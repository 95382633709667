<template>
  <Transition>
    <div v-if="show" class="popup-container" @click.self="handleClose">
      <div class="popup-content">
        <div class="popup-title no-text">
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <div class="popup-empty-page">
            <div class="image">
              <img width="88" height="88" src="/images/store-big.svg" alt="">
            </div>
            <h2>Одновременно в корзине могут находиться товары только одного продавца</h2>
            <p class="note">При добавлении в корзину товаров, добавленные ранее товары будут удалены!</p>
            <div class="lk-radio-block">
              <label>
                <input v-model="action" value="completeOrder" type="radio" name="sellers" />
                <i />
                <span>Завершить заказ товаров продавца {{ cartPartner?.id ? `«${cartPartner?.title || cartPartner?.slug}»` : '' }}</span>
              </label>
            </div>
            <div class="lk-radio-block">
              <label>
                <input v-model="action" value="addToCart" type="radio" name="sellers" />
                <i />
                <span>Очистить корзину и добавить товар продавца {{ cashedProduct?.partner?.id ? `«${cashedProduct.partner?.title || cashedProduct.partner?.slug}»` : '' }}</span>
              </label>
            </div>
            <button class="btn" :disabled="!action?.length" @click.prevent="handleSubmit">
              Продолжить
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import { useCartStore } from "~/store/cart";
import { useModalStore } from "~/store/modal";
import { usePartnerStore } from "~/store/partners";

import type { ProductCart } from "~/types/types";

const route = useRoute();
const router = useRouter();

const store = {
  cart: useCartStore(),
  modal: useModalStore(),
  partner: usePartnerStore()
};
const { cartPartner, cashedProduct } = storeToRefs(store.cart);

const { setPartner } = store.partner;
const { addToCart, clearCart } = store.cart;

// form
const action = ref<string | null>(null)
const handleSubmit = async () => {
  if (action.value === 'completeOrder') {
    if (cartPartner.value) setPartner(cartPartner.value);
    
    const query = { ...route.query };
    query.action = 'completeOrder';
    router.replace({ query });
  } else if (action.value === 'addToCart') {
    clearCart()
    await addToCart(cashedProduct.value)
  }
  handleClose();

  return action.value;
}

// modal
const { modal } = storeToRefs(store.modal);
const show = computed(() => {
  return modal.value.partnerWarn;
});
const handleClose = () => {
  action.value = null
  cashedProduct.value = {} as ProductCart

  store.modal.closeModal("partnerWarn");
};
</script>
