interface WordStyle {
    word: string;
    style: Record<string, string> | null;
}

export function useAddressProcessing() {
    const processAddress = (address: string): WordStyle[] => {
        return address.split(' ').map((word): WordStyle => ({
            word: word,
            style: word.length > 22 ? { 'word-break': 'break-all' } : null
        }));
    };

    return {
        processAddress
    };
}