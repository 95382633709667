<template>
  <Transition>
    <div
      v-if="show"
      key="modal-feedback"
      class="popup-container"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title">
          <a href="#" class="link-back" @click.prevent="handleBack">
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <h2>Связаться с нами</h2>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <Loader v-if="loading" />
          <div class="lk-content">
            <form
              action="#"
              class="popup-form simple-form"
              @submit.prevent="handleSubmit"
            >
              <div class="input-label">
                Здесь вы можете написать любые вопросы и написать какие товары
                вы еще хотели бы видеть на сайте
              </div>
              <div class="input-block">
                <CommonTextarea
                  v-model="text"
                  placeholder="Введите сообщение"
                  :max-length="1024"
                  :disabled="loading"
                />
              </div>
              <button
                type="submit"
                class="btn"
                :disabled="text?.trim()?.length < 5 || loading"
              >
                Отправить
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useModalStore } from "~/store/modal";
import { useProfileStore } from "~/store/profile";

interface SettingForm {
  text: string;
}

export default defineComponent({
  setup() {
    // store
    const store = {
      modal: useModalStore(),
      profile: useProfileStore(),
    };
    const { modal, openModal, closeModal, closeAllModals } = store.modal;
    const { sendFeedback, updateBody } = store.profile;
    const { loading } = storeToRefs(store.profile);

    // form
    const form = reactive<SettingForm>({
      text: "",
    });

    const handleSubmit = async () => {
      const { text } = form;

      if (text?.trim()?.length) {
        try {
          updateBody({ text }, "feedback");
          await sendFeedback();

          form.text = "";
        } catch (e) {
          console.error("handleSubmit error", e);
        }
      }
    };

    // modal
    const show = computed(() => {
      return modal.feedback;
    });
    const handleClose = () => {
      closeModal("feedback");
    };
    const handleBack = () => {
      handleClose();
      openModal("profile");
    };

    return {
      ...toRefs(form),
      handleSubmit,

      loading,

      // modal
      show,
      handleClose,
      closeAllModals,
      handleBack,
    };
  },
});
</script>
