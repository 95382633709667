<template>
  <Transition>
    <div
      v-if="show"
      key="modal-profile"
      class="popup-container"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title">
          <a href="#" class="link-back" @click.prevent="handleBack">
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <h2>Редактирование товаров</h2>
            <div class="subtitle">Категории</div>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <div class="lk-content" style="position: relative">
            <Loader v-if="loading" />
            <ul class="lk-menu">
              <li v-for="cat in categories" :key="cat.id">
                <a href="#" @click.prevent="handleOpen(cat)">
                  <p>{{ cat.title }}</p>
                  <div class="orders">{{ cat.productsCount || 0 }}</div>
                  <i v-if="cat.productsCount" class="arrow" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useAdminStore } from "~/store/admin";
import { useModalStore } from "~/store/modal";

import type { Category } from "~/types/types";

export default defineComponent({
  setup() {
    const store = {
      admin: useAdminStore(),
      modal: useModalStore(),
    };
    const { updateParams } = store.admin;
    const { loading, categories, edit } = storeToRefs(store.admin);

    // modal
    const show = computed(() => {
      const { modal } = store.modal;
      return modal.categories;
    });
    const handleOpen = (category: Category) => {
      edit.value.category = category;
      updateParams({ categoryId: category.id });

      store.modal.closeModal("categories");
      store.modal.openModal("products");
    };
    const handleBack = () => {
      store.modal.closeModal("categories");
      store.modal.openModal("admin");
    };
    const handleClose = () => {
      store.modal.closeModal("categories");
    };
    watch(
      () => show.value,
      async (status: boolean) => {
        if (status) await store.admin.fetchAdminCategories();
      },
      { immediate: true }
    );

    return {
      // state
      loading,
      categories,

      // modal
      show,
      handleOpen,
      handleBack,
      handleClose,
    };
  },
});
</script>

<style scoped>
button:disabled {
  pointer-events: none;
  opacity: 0.7;
}
</style>