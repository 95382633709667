<template>
  <div v-if="show" class="cookie-block">
    <div class="text">Сайт использует файлы Cookie</div>
    <a href="#" class="btn empty-btn" @click.prevent="confirmCookie">Понятно</a>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  setup() {
    const show = ref<boolean>(false);

    const confirmCookie = () => {
      show.value = false;
      localStorage.setItem("cookieConfirmed", "true");
    };

    onMounted(() => {
      show.value = !localStorage.getItem("cookieConfirmed");
    });

    return {
      show,
      confirmCookie,
    };
  },
});
</script>