<template>
  <Transition>
    <div
      v-if="show"
      key="modal-checkoutDetail"
      class="popup-container"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title">
          <a href="#" class="link-back" @click.prevent="handleClose">
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <h2>Ваш заказ</h2>
            <div v-if="cartPartner?.id" class="seller">
              <img width="16" height="16" src="/images/store.svg" alt="">
              {{ cartPartner?.title || cartPartner?.slug }}
            </div>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <Loader v-if="productLoading" />
          <div class="lk-order-open">
            <template v-if="cart.products?.length">
              <div
                v-for="product in cart.products"
                :key="product.id"
                class="cart-product"
              >
                <a
                  :href="`?product=${product.slug}`"
                  class="image"
                  @click.prevent="openProduct(product.slug)"
                >
                  <CommonImage
                    :data="product.mainImage"
                    :height="71"
                    :width="71"
                  />
                </a>
                <div class="inner">
                  <div class="name">
                    <a
                      :href="`?product=${product.slug}`"
                      :class="{ disabled: loading }"
                      @click.prevent="openProduct(product.slug)"
                    >
                      {{ product.title || "Неизвестный продукт" }}
                    </a>
                  </div>
                  <div class="available">
                    <img
                      width="16"
                      height="16"
                      :src="product.isWeight ? '/images/weigher.svg' : '/images/box.svg'"
                      alt=""
                    />
                    {{ !!product.priceKilo ? `${product.unit} / ${priceFormat(product.priceKilo || 0)} за кг` : product.unit || "0 г" }}
                  </div>
                  <div class="counting">
                    <div class="product-amount">
                      <span
                        class="qtyminus"
                        :class="{ disabled: loading }"
                        @click="handleRemove(product.id)"
                      >
                        <img
                          width="16"
                          height="16"
                          src="/images/minus.svg"
                          alt=""
                        />
                      </span>
                      <input
                        v-maska
                        data-maska="###"
                        type="text"
                        class="qty"
                        :value="product.count || 0"
                        :disabled="
                          loading ||
                          product.count >= product.quantityStock ||
                          product.count >= limits.maxProductCount
                        "
                        @blur="handleInput($event, product)"
                      />
                      <span
                        v-if="
                          product.count < product.quantityStock &&
                          product.count < limits.maxProductCount
                        "
                        class="qtyplus"
                        :class="{
                          disabled: loading,
                        }"
                        @click="addToCart(product)"
                      >
                        <img
                          width="16"
                          height="16"
                          src="/images/plus.svg"
                          alt=""
                        />
                      </span>
                    </div>
                    <div class="price-block">
                      <div v-if="product.priceDiscount" class="old-price">
                        {{ priceFormat(product.cost) }}
                      </div>
                      <div
                        class="price"
                        :class="{ discount: product.priceDiscount }"
                      >
                        {{ priceFormat(product.priceDiscount ?? product.cost) }}
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href="#"
                  class="remove"
                  :class="{ discount: product.priceDiscount }"
                  @click.prevent="handleRemove(product.id, true)"
                >
                  <img width="20" height="20" src="/images/close.svg" alt="" />
                </a>
              </div>
            </template>
            <Loader v-else-if="loading" />
            <template v-else>
              <p style="text-align: center">Товары отсутствуют</p>
            </template>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useStore } from "~/store";
import { useCartStore } from "~/store/cart";
import { useModalStore } from "~/store/modal";

import type { ProductCart } from "~/types/types";

export default defineComponent({
  setup() {
    const { metaData, replaceUrlWithoutNavigation } = useSeoTags();

    // store
    const store = {
      cart: useCartStore(),
      main: useStore(),
      modal: useModalStore(),
    };

    // data
    const { productLoading } = storeToRefs(store.main);
    const { cart, cartPartner, limits, loading } = storeToRefs(store.cart);

    // cart
    const { addToCart, removeFromCart } = store.cart;
    const handleRemove = async (
      productId: string,
      removeCompletely?: boolean
    ) => {
      await removeFromCart(productId, removeCompletely);

      if (!cart.value.products.length) {
        await new Promise((resolve) => setTimeout(resolve, 250));
        closeAllModals();
      }
    };
    const handleInput = async (e: InputEvent, product: ProductCart) => {
      const value = Number((e.target as HTMLInputElement).value);
      if (typeof value === "number") {
        loading.value = true;

        await new Promise((resolve) => setTimeout(resolve, 250));
        addToCart(product, value);

        loading.value = false;
      }
    };

    // modal
    const { modal, closeModal, closeAllModals } = store.modal;
    const show = computed(() => {
      return modal.checkoutDetail;
    });
    const handleClose = () => {
      closeModal("checkoutDetail");
    };
    // products
    const openProduct = async (slug: string) => {
      try {
        if (slug) {
          await store.main.fetchProductByArg(slug);
          useModalStore().openModal("product");

          /*
          const query = { ...route.query };
          query.product = slug;
          router.replace({ query });
          */

          const { product } = storeToRefs(store.main);
          if (product.value?.id) {
            useHead(metaData(product.value) as object);

            const { category } = product.value;
            const url = `${category?.slug ? `${category.slug}/` : ""}${slug}`;
            replaceUrlWithoutNavigation(url);
          }
        }
      } catch (e) {
        console.error("openProduct err", e);
      }
    };

    const { priceFormat } = usePriceFormat();

    return {
      // data
      cart,
      cartPartner,
      limits,
      productLoading,
      loading,

      // cart methods
      addToCart,
      handleInput,
      handleRemove,

      // modal
      show,
      handleClose,
      closeAllModals,
      openProduct,

      priceFormat,
    };
  },
});
</script>

<style scoped>
.cart-product {
  display: flex;
  align-items: flex-start;
}
</style>