<template>
  <div>
    <template v-for="(wordObj, index) in processAddress(text)" :key="index">
      <span v-if="wordObj.style" :style="wordObj.style">
        {{ `${wordObj.word} ` }}
      </span>
      <template v-else>{{ `${wordObj.word} ` }}</template>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { processAddress } = useAddressProcessing();
    return {
      processAddress,
    };
  },
});
</script>