<template>
  <Transition>
    <div
      v-if="show"
      :key="category.id"
      class="popup-container"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title">
          <a href="#" class="link-back" @click.prevent="handleBack">
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <h2>{{ category.title }}</h2>
            <div class="subtitle">Редактирование</div>
          </div>
          <div class="close-modal" @click.prevent="handleClose">
            <img width="20" height="20" src="/images/close.svg" alt="" />
          </div>
        </div>
        <CommonSearchBar type="popup" v-model="search" @clear="search = ''" />
        <div class="popup-description" @scroll="handleScroll">
          <div class="lk-order-open" style="position: relative">
            <template v-if="products?.length">
              <div
                v-for="product in products"
                :key="product.id"
                class="cart-product"
              >
                <div class="content">
                  <a
                    :href="`?product=${product.slug}`"
                    class="image"
                    @click.prevent="openProduct(product)"
                  >
                    <img
                      v-if="product.mainImage"
                      :height="71"
                      :width="71"
                      :src="product.mainImage"
                      alt=""
                    />
                  </a>
                  <div class="inner">
                    <div class="name">
                      <a
                        :href="`?product=${product.slug}`"
                        :class="{ disabled: loading }"
                        @click.prevent="openProduct(product)"
                      >
                        {{ product.title || "Продукт без имени" }}
                      </a>
                    </div>
                    <div class="available">
                      <img
                        width="16"
                        height="16"
                        :src="product.isWeight ? '/images/weigher.svg' : '/images/box.svg'"
                        alt=""
                      />
                      {{ !!product.priceKilo ? `${product.unit} / ${priceFormat(product.priceKilo || 0)} за кг` : product.unit || "0 г" }}
                    </div>
                  </div>
                </div>
                <ul class="lk-info-list">
                  <li v-if="hasPermission('change_products')">
                    <div class="type orange">Показать покупателям?</div>
                    <div class="value">
                      <div class="value-radio">
                        <div class="radio-block">
                          <label>
                            <input
                              type="radio"
                              :name="`radio-${product.id}`"
                              :checked="product.active === true"
                              :disabled="disabled"
                              :value="true"
                              @change="() => handleChange(product.id, true)"
                            />
                            <i />
                            <span>Да</span>
                          </label>
                        </div>
                        <div class="radio-block">
                          <label>
                            <input
                              type="radio"
                              :name="`radio-${product.id}`"
                              :checked="product.active === false"
                              :disabled="disabled"
                              :value="false"
                              @change="() => handleChange(product.id, false)"
                            />
                            <i />
                            <span>Нет</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div class="type">В наличии</div>
                    <div class="value">{{ product?.quantityStock || 0 }}</div>
                  </li>
                  <li v-if="!!product.price">
                    <div class="type">Цена</div>
                    <div class="value">
                      {{ priceFormat(product.price) }}
                    </div>
                  </li>
                  <li v-if="!!product.priceKilo && product.isWeight">
                    <div class="type">Цена за кг</div>
                    <div class="value">
                      {{ priceFormat(product.priceKilo) }}
                    </div>
                  </li>
                </ul>
                <a
                  v-if="hasPermission('change_products')"
                  href="#"
                  class="btn empty-btn"
                  :class="{ disabled }"
                  @click.prevent="() => handleOpen(product)"
                >
                  Редактировать
                </a>
              </div>
            </template>
            <div v-else class="not-found-description">
              <h4>Ничего не найдено.</h4>
              <div class="inner">
                <img
                  width="30"
                  height="30"
                  src="/images/file-question.svg"
                  alt=""
                />
                <p>
                  К сожалению, в нашем каталоге нет товаров, подходящих под
                  введенный поисковый запрос.
                </p>
              </div>
            </div>
            <Loader v-if="loading" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useStore } from "~/store";
import { useAdminStore } from "~/store/admin";
import { useModalStore } from "~/store/modal";

import type { ProductInfo } from "~/types/types";

export default defineComponent({
  setup() {
    // store
    const store = {
      admin: useAdminStore(),
      main: useStore(),
      modal: useModalStore(),
    };
    const { hasPermission } = store.admin;
    const {
      loading,
      params,
      default: defaultPagination,
      edit,
      products,
      pagination,
    } = storeToRefs(store.admin);
    const category = computed(() => {
      return edit.value.category;
    });

    // <>
    const disabled = ref<boolean>(false);
    const handleChange = async (productId: string, status: boolean) => {
      store.admin.clearBody();

      if (productId) {
        disabled.value = true;
        try {
          store.admin.updateBody({ active: !!status }, "product");
          await store.admin.updateProduct(productId);
        } catch (e) {
          console.error("handleChange error", e);
        } finally {
          disabled.value = false;
        }
      }
    };
    // </>

    // modal
    const show = computed(() => {
      const { modal } = store.modal;
      return modal.products;
    });
    const clear = () => {
      search.value = "";
      store.admin.clearProductsData();
    };
    const handleOpen = (product: ProductInfo) => {
      edit.value.product = product;

      store.modal.closeModal("products");
      store.modal.openModal("editProduct");
    };
    const handleBack = () => {
      store.modal.closeModal("products");
      store.modal.openModal("categories");

      clear();
    };
    const handleClose = () => {
      store.modal.closeModal("products");

      clear();
    };
    watch(
      () => show.value,
      async (status: boolean) => {
        if (status && products.value.length === 0) {
          await store.admin.fetchAdminProducts(true);
        }
      },
      { immediate: true }
    );
    // open product
    const openProduct = async (openProduct: ProductInfo) => {
      try {
        if (openProduct) {
          // await store.main.fetchProductByArg(openProduct.slug);
          const { product } = storeToRefs(store.admin);

          product.value = openProduct;
          store.modal.openModal("openProduct");
        }
      } catch (e) {
        console.error("openProduct err", e);
      }
    };

    // infinity scroll
    const handleScroll = (event: Event) => {
      const target = event.target as HTMLElement;
      const atBottom =
        target.scrollTop + target.clientHeight >= target.scrollHeight - 1;

      if (atBottom && !loading.value) {
        loadMore();
      }
    };
    const loadMore = async () => {
      if (pagination.value.products.hasPages) {
        const { page } = defaultPagination.value.products;

        if (params.value?.products.page) {
          params.value.products.page += page;
        } else {
          params.value.products.page = page;
        }

        try {
          await store.admin.fetchAdminProducts();
        } catch (e) {
          console.error("loadMore error", e);
        }
      }
    };

    // search
    const search = ref<string>("");
    const handleSearch = async (search: string | null) => {
      store.admin.replaceParams({
        page: 1,
        categoryId: category.value.id,
        search,
      });

      try {
        await store.admin.fetchAdminProducts(true);
      } catch (e) {
        console.error("handleSearch error", e);
      }
    };
    watch(
      () => search.value,
      async (text, prev) => {
        if (text !== null && text !== prev) {
          if (show.value) await handleSearch(text || null);
        }
      }
    );
    const { priceFormat } = usePriceFormat();

    return {
      // state
      disabled,
      loading,
      category,
      products,
      search,
      hasPermission,

      handleChange,
      handleScroll,
      openProduct,

      // modal
      show,
      handleOpen,
      handleBack,
      handleClose,

      // composables
      priceFormat,
    };
  },
});
</script>

<style lang="less" scoped>
button:disabled {
  pointer-events: none;
  opacity: 0.8;
}
.radio-block {
  input:disabled {
    pointer-events: none;
    opacity: 0;
  }
}
</style>