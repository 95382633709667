<template>
  <Transition>
    <div
      v-if="show"
      key="modal-address"
      class="popup-container"
      @click.self="handleClose"
    >
      <!-- LIST -->
      <div class="popup-content">
        <div class="popup-title">
          <a
            href="#"
            class="link-back"
            @click.prevent="mode ? handleChangeMode() : handleBack()"
          >
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <template v-if="mode === 'add'">
              <h2>Адреса доставки</h2>
              <div class="subtitle">Добавления адреса доставки</div>
            </template>
            <template v-else-if="mode === 'remove'">
              <h2>Удаление адреса</h2>
            </template>
            <template v-else>
              <h2>Адреса доставки</h2>
              <div class="subtitle">Выбрать адрес</div>
            </template>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <Loader v-if="loading" />
          <template v-if="mode === 'add'">
            <div class="lk-content">
              <form
                action="#"
                class="popup-form simple-form"
                @submit.prevent="handleAdd"
              >
                <div class="input-block">
                  <CommonTextarea
                    v-model="address"
                    :disabled="loading"
                    placeholder="Введите новый адрес доставки"
                  />
                </div>
                <button
                  type="submit"
                  class="btn"
                  :disabled="loading || address?.length < 5"
                >
                  Сохранить адрес доставки
                </button>
              </form>
            </div>
          </template>
          <template v-else-if="mode === 'remove'">
            <div class="lk-content">
              <div class="address-remove">
                <p>Вы уверены?</p>
                <div class="flex-block">
                  <a
                    href="#"
                    class="btn empty-btn"
                    :class="{ disabled: loading }"
                    @click.prevent="handleDelete"
                  >
                    Да
                  </a>
                  <a
                    href="#"
                    class="btn"
                    :class="{ disabled: loading }"
                    @click.prevent="handleChangeMode"
                  >
                    Нет
                  </a>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="lk-content">
              <template v-if="addresses?.length">
                <div
                  v-for="{ address, id } in addresses"
                  :key="id"
                  class="lk-radio-menu"
                  :class="{ 'with-remove': addresses.length === 1 }"
                >
                  <div class="lk-radio-block">
                    <label>
                      <input
                        v-model="addressID"
                        type="radio"
                        name="address"
                        :value="id"
                        :disabled="loading"
                        @change="handleChange(id)"
                      />
                      <i />
                      <FilterTextProcessing :text="address" />
                    </label>
                    <div
                      class="lk-radio-remove"
                      @click.prevent="handleRemove(id)"
                    >
                      <img
                        width="20"
                        height="20"
                        src="/images/close.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </template>
              <a
                href="#"
                class="btn"
                :class="{ disabled: loading }"
                @click.prevent="handleChangeMode('add')"
              >
                Добавить адрес доставки
              </a>
            </div>
          </template>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useModalStore } from "~/store/modal";
import { useProfileStore } from "~/store/profile";

interface SettingForm {
  address: string;
  addressID: string;
  deleteId: string;
}

type Mode = "add" | "remove" | null;

export default defineComponent({
  setup() {
    // store
    const store = {
      modal: useModalStore(),
      profile: useProfileStore(),
    };
    const { modal, openModal, closeModal, closeAllModals } = store.modal;
    const {
      addAddress,
      deleteAddress,
      saveAddressToLocalStorage,
      fetchAddresses,
      updateBody,
    } = store.profile;
    const { addresses, loading, savedAddress } = storeToRefs(store.profile);

    // form
    const form = reactive<SettingForm>({
      address: "",
      addressID: "",
      deleteId: "",
    });
    const handleChange = (id: string) => {
      saveAddressToLocalStorage(id);
    };

    const getAddressFromLocalStorage = () => {
      form.addressID = savedAddress.value;
    };
    const handleAdd = async () => {
      const { address } = form;

      if (address?.trim()?.length) {
        try {
          updateBody({ address }, "address");
          await addAddress();

          handleChangeMode();
          form.address = "";

          getAddressFromLocalStorage();
        } catch (e) {
          console.error("handleAdd error", e);
        }
      }
    };
    const handleRemove = (id: string) => {
      form.deleteId = id;
      handleChangeMode("remove");
    };
    const handleDelete = async () => {
      try {
        await deleteAddress(form.deleteId);

        getAddressFromLocalStorage();
        handleChangeMode();
      } catch (e) {
        console.error("handleRemove error", e);
      }
    };

    const mode = ref<Mode>(null);
    const handleChangeMode = (m: Mode = null) => {
      mode.value = m;
    };

    // modal
    const show = computed(() => {
      return modal.address;
    });
    const handleClose = () => {
      closeModal("address");
    };
    const handleBack = () => {
      handleClose();
      openModal("profile");
    };

    watch(
      () => show.value,
      async (status: boolean) => {
        if (status) {
          await fetchAddresses();
          getAddressFromLocalStorage();
        } else {
          mode.value = null;

          form.address = "";
          form.addressID = "";
        }
      }
    );
    watch(
      () => addresses.value?.length,
      (length: number) => {
        if (!length) handleChange("");
      }
    );

    return {
      ...toRefs(form),
      handleChange,

      handleAdd,
      handleDelete,
      handleRemove,

      addresses,
      loading,

      mode,
      handleChangeMode,

      // modal
      show,
      handleClose,
      closeAllModals,
      handleBack,
    };
  },
});
</script>
