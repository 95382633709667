<template>
  <Transition>
    <div
      v-if="show"
      key="modal-signin"
      @click.self="handleClose"
      class="popup-container"
    >
      <div class="popup-content">
        <div class="popup-title">
          <div class="inner">
            <h2>Вход</h2>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <Loader v-if="loading" />
          <div class="lk-content">
            <form
              action="#"
              class="popup-form simple-form"
              @submit.prevent="handleSubmit"
            >
              <template>
                <!-- ранее был телефон -->
                <div class="input-label">Введите ваш номер для регистрации</div>
                <div class="input-block">
                  <input
                    v-model="phone"
                    type="tel"
                    v-maska
                    data-maska="+7 (###) ### ## ##"
                    placeholder="+7 (XХХ) ХХХ ХХ ХХ"
                    :disabled="loading"
                  />
                </div>
              </template>
              <div class="input-label">
                Введите адрес электронной почты для получения кода авторизации
              </div>
              <div class="input-block">
                <input
                  v-model="email"
                  type="text"
                  placeholder="example@email.com"
                  :disabled="loading"
                  @keydown="handlePrevent"
                />
              </div>
              <button
                type="submit"
                class="btn"
                :disabled="email?.length < 5 || loading"
              >
                Получить код
              </button>
            </form>
          </div>
        </div>
        <div class="enter-note">
          Продолжая авторизацию, вы соглашаетесь с
          <a href="#">политикой конфиденциальности</a>,
          <a href="#">условиями сервиса</a> и
          <a href="#">условиями продажи товаров</a>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useAuthStore } from "~/store/auth";
import { useModalStore } from "~/store/modal";
import { useErrorStore } from "~/store/error";

export default defineComponent({
  setup() {
    const store = {
      auth: useAuthStore(),
      modal: useModalStore(),
      error: useErrorStore(),
    };
    const show = computed(() => {
      const { modal } = store.modal;
      return modal.signin;
    });
    const handleClose = () => {
      store.modal.closeModal("signin");
    };

    // auth
    // const phone = ref<string>("");
    const email = ref<string>("");
    const loading = computed(() => {
      return store.auth.loading;
    });

    const handleSubmit = async () => {
      /*
      const regex = {
        phone: /^\+79\d{9}$/,
        filter: /[^\d+]/g,
      };
      const filteredNumber = phone.value.replace(regex.filter, "");
      */
      const emailRegex: RegExp =
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (emailRegex.test(email.value)) {
        try {
          store.auth.updateBody({ email: email.value?.trim() });
          const status = await store.auth.sendCode();

          // если код отправлен
          if (status === 1) {
            store.modal.openModal("otp");
          }
          // если код уже был отправлен
          else if (status === 4142) {
            store.modal.openModal("otp");
          }
          // на один номер можно получить код авторизации 3 раза в сутки
          else if (status === 4146) {
            email.value = "";
          }
          // если пользователь заблокирован
          else if (status === 4242) {
            handleClose();
          }
        } catch (e: any) {
          console.error("handleSubmit error", e);
        }
      } else {
        await new Promise((resolve) => setTimeout(resolve, 500));
        // инициируем пользовательскую ошибку
        /*
        const error = {
          response: {
            data: {
              code: 400,
              message:
                "Номер должен соответствовать формату телефонного номера +7 (9ХХ) ХХХ ХХ ХХ",
              statusCode: 400,
            },
            status: 400,
            statusText: "Custom error",
          },
          message: "",
        };
        */
        const error = {
          response: {
            data: {
              code: 400,
              message:
                "Введите действительный адрес электронной почты в стандартном формате, например, <b>example@email.com</b>.",
              statusCode: 400,
            },
            status: 400,
            statusText: "Custom error",
          },
          message: "",
        };
        store.error.setError(error);
        email.value = "";
      }
    };
    const handlePrevent = (event: KeyboardEvent) => {
      // блокируем возможность юзать пробел
      if (event.key === " ") {
        event.preventDefault();
      }
    };

    watch(
      () => show.value,
      (status: boolean) => {
        if (!status) email.value = "";
      }
    );

    return {
      // modal
      show,
      handleClose,

      // auth
      email,
      loading,
      handleSubmit,
      handlePrevent,
    };
  },
});
</script>

<style scoped>
input:disabled,
button:disabled {
  pointer-events: none;
  opacity: 0.7;
}
</style>