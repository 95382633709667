<template>
  <div
    class="my-select"
    ref="selectContainer"
    @click="toggleDropdown"
    @selectstart.prevent
  >
    <div class="selected-value">
      {{ selectedValue || placeholder }}
    </div>
    <Transition>
      <div class="dropdown" v-if="isOpen">
        <div
          class="dropdown-item"
          v-for="option in options"
          :key="option.value"
          :style="`color: ${selectedValue === option.value ? '#ff7043' : ''}`"
          @click="selectValue(option)"
        >
          {{ option.label }}
        </div>
      </div>
    </Transition>
    <img
      class="my-select__arrow"
      :class="{ isOpen }"
      src="/images/select-arrow.svg"
      alt=""
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onUnmounted } from "vue";

export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: "Выберите значение",
    },
    options: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const isOpen = ref(false);
    const selectedValue = ref("");
    const selectContainer = ref<HTMLElement | null>(null);

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value;
    };

    const selectValue = (option: { label: string; value: string }) => {
      selectedValue.value = option.label;
      isOpen.value = false;
      emit("update:modelValue", option.value);
    };

    const onClickOutside = (event: MouseEvent) => {
      if (
        selectContainer.value &&
        event.target instanceof Node &&
        !selectContainer.value.contains(event.target)
      ) {
        isOpen.value = false;
      }
    };

    onMounted(() => {
      document.addEventListener("click", onClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener("click", onClickOutside);
    });

    return {
      isOpen,
      selectedValue,
      toggleDropdown,
      selectValue,
      selectContainer,
    };
  },
});
</script>

<style lang="less" scoped>
.my-select {
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  transition: all 0.3s ease-out;
  padding: 0 19px;
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  font-family: Nunito, Arial, Tahoma, sans-serif;
  background: #f9f9fb;
  border-radius: 17px;
  border: 1px solid #e9e9e9;
  user-select: none;

  position: relative;

  &__arrow {
    position: absolute;
    width: 10px;
    height: auto;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: transform 250ms ease;

    &.isOpen {
      transform: translateY(-50%) rotate(180deg);
      transition: transform 250ms ease;
    }
  }
}

.dropdown {
  font-size: 14px;
  line-height: 19.2px;
  background: #fff;
  border-radius: 17px;
  border-top: none;
  box-sizing: border-box;
  box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  user-select: none;
  max-height: 265px;
  overflow-y: auto;
  padding: 10px 20px;
  position: absolute;
  top: 56px;
  left: 0;
  z-index: 100;

  &::-webkit-scrollbar {
    width: 3px;
    background: #f9f9fb;
  }
  &::-webkit-scrollbar-thumb {
    border: none;
    border-radius: 6px;
    background: #000;
  }
}

.dropdown-item {
  padding: 10px 0;
  cursor: pointer;
  transition: color 250ms ease;
  &:not(:last-child) {
    border-bottom: 1px solid #e9e9e9;
  }
}

.dropdown-item:hover {
  color: #ff7043;
  transition: color 250ms ease;
}

.selected-value {
  cursor: pointer;
}
</style>
