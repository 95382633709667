<template>
  <div class="modals">
    <TransitionGroup>
      <div v-show="hideAdminModals" key="modal-admin">
        <ModalAdmin />
        <ModalCity />
      </div>
      <div v-show="hideOtherModals && hideCheckoutModal" key="modal-checkout">
        <ModalCheckout />
      </div>
      <div v-show="hideCheckoutDetailModal" key="modal-detail">
        <ModalCheckoutDetail />
      </div>
      <div v-show="hideOtherModals" key="modals">
        <ModalClearCart />
        <ModalOtp />
        <ModalOutStock />
        <ModalPartnerWarn />
        <ModalProduct />
        <ModalProfile />
        <ModalSignIn />
        <ModalPhoneNumber />
      </div>
      <div v-show="!hideOtherModals" key="modal-share">
        <ModalShare />
      </div>
    </TransitionGroup>
    <ModalError />
  </div>
</template>

<script lang="ts">
import { useModalStore } from "~/store/modal";

export default defineComponent({
  setup() {
    const { modal } = useModalStore();

    const hideAdminModals = computed(() => {
      return !modal.error;
    });
    const hideOtherModals = computed(() => {
      return !modal.share;
    });
    const hideCheckoutModal = computed(() => {
      return !modal.checkoutDetail;
    });
    const hideCheckoutDetailModal = computed(() => {
      return !modal.outstock && !modal.product;
    });

    return {
      hideAdminModals,
      hideOtherModals,
      hideCheckoutModal,
      hideCheckoutDetailModal,
    };
  },
});
</script>

<style>
.popup-description {
  position: relative;
}
</style>