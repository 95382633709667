<template>
  <Transition>
    <div
      v-if="show"
      key="modal-orders"
      class="popup-container"
      @click.self="handleChangeMode(), handleClose()"
    >
      <div class="popup-content">
        <div class="popup-title">
          <a
            href="#"
            class="link-back"
            :class="{ disabled: loading }"
            @click.prevent="mode ? handleChangeMode() : handleBack()"
          >
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <h2>Заказы</h2>
            <div class="subtitle">
              {{ modeTitle }}
              {{ ordersCount[mode]?.count || "" }}
            </div>
          </div>
          <CommonCloseButton @close="handleChangeMode" />
        </div>
        <template v-if="mode">
          <div class="popup-description" @scroll="handleScroll">
            <div
              class="lk-content"
              style="margin: 0"
              :style="!orders.length && loading ? '' : 'position: relative;'"
            >
              <Loader v-if="loading" />
              <ul v-if="orders.length" class="lk-orders-list">
                <li v-for="order in orders" :key="order.id">
                  <a
                    href="#"
                    class="lk-orders-link"
                    :class="{ disabled: loading }"
                    @click.prevent="handleOpen(order.id)"
                  >
                    <div class="number">Заказ №{{ order?.serialNumber }}</div>
                    <div class="date">
                      от
                      {{
                        $dayjs(order.updateDT || order.createDT || null).format(
                          "DD.MM.YYYY HH:mm"
                        )
                      }}
                    </div>
                    <div class="price">
                      Сумма {{ order.totalCost || 0 }} руб
                    </div>
                    <div v-if="order.partner?.id" class="seller">
                      <img width="16" height="16" src="/images/store.svg" alt="">
                      {{ order.partner?.title || order.partner?.slug }}
                    </div>
                  </a>
                </li>
              </ul>
              <Loader v-else-if="loading" />
              <p v-else style="text-align: center">Заказы не найдены</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="popup-description">
            <div class="lk-content">
              <ul class="lk-menu">
                <li>
                  <a
                    href="#"
                    :class="{ disabled: !ordersCount[30]?.count || loading }"
                    @click.prevent="handleChangeMode(30)"
                  >
                    <p>На сборке</p>
                    <div class="orders">
                      {{ ordersCount[30]?.count || 0 }}
                    </div>
                    <div v-if="ordersCount[30]?.actionsCount" class="count">
                      {{ ordersCount[30]?.actionsCount }}
                    </div>
                    <i v-if="ordersCount[30]?.count" class="arrow" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    :class="{ disabled: !ordersCount[50]?.count || loading }"
                    @click.prevent="handleChangeMode(50)"
                  >
                    <p>В пути</p>
                    <div class="orders">
                      {{ ordersCount[50]?.count || 0 }}
                    </div>
                    <div v-if="ordersCount[50]?.actionsCount" class="count">
                      {{ ordersCount[50]?.actionsCount }}
                    </div>
                    <i v-if="ordersCount[50]?.count" class="arrow" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    :class="{ disabled: !ordersCount[100]?.count || loading }"
                    @click.prevent="handleChangeMode(100)"
                  >
                    <p>Доставлены</p>
                    <div class="orders">
                      {{ ordersCount[100]?.count || 0 }}
                    </div>
                    <div v-if="ordersCount[100]?.actionsCount" class="count">
                      {{ ordersCount[100]?.actionsCount }}
                    </div>
                    <i v-if="ordersCount[100]?.count" class="arrow" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    :class="{ disabled: !ordersCount[-100]?.count || loading }"
                    @click.prevent="handleChangeMode(-100)"
                  >
                    <p>Отменены</p>
                    <div class="orders">
                      {{ ordersCount[-100]?.count || 0 }}
                    </div>
                    <div v-if="ordersCount[-100]?.actionsCount" class="count">
                      {{ ordersCount[-100]?.actionsCount }}
                    </div>
                    <i v-if="ordersCount[-100]?.count" class="arrow" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useModalStore } from "~/store/modal";
import { useProfileStore } from "~/store/profile";

import type { OrderParameters, OrderState } from "~/types/types";

type Mode = OrderState | null;

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    // store
    const store = {
      modal: useModalStore(),
      profile: useProfileStore(),
    };
    const { modal, openModal, closeModal, closeAllModals } = store.modal;
    const {
      fetchOrder: storeFetchOrder,
      fetchOrders: storeFetchOrders,
      fetchOrdersCount,
    } = store.profile;
    const { loading, orders, ordersCount, ordersPagination, totalCount } =
      storeToRefs(store.profile);

    const mode = ref<Mode>(null);
    const modeTitle = computed(() => {
      switch (mode.value) {
        case 30:
          return "На сборке";
        case 50:
          return "В пути";
        case 100:
          return "Доставлены";
        case -100:
          return "Отменены";

        default:
          return "Все";
      }
    });
    const handleChangeMode = (m: Mode = null) => {
      mode.value = m;

      const query = { ...route.query };
      delete query.mode;
      router.replace({ query });
    };

    // order data + infinity scroll
    const state = {
      defaultParams: {
        page: 1,
        pageSize: 10,
      },
    };
    const params = ref<OrderParameters>({
      page: state.defaultParams.page,
      pageSize: state.defaultParams.pageSize,
    });
    const setDefaultParams = () => {
      // default pagination
      params.value = { ...state.defaultParams };
    };
    const fetchOrders = async (mode: Mode, deletePrev: boolean = true) => {
      if (mode) {
        await storeFetchOrders(
          {
            page: params.value.page,
            pageSize: params.value.pageSize,
            state: mode,
          },
          deletePrev
        );
      } else {
        return false;
      }
    };
    const handleScroll = async (event: Event) => {
      const target = event.target as HTMLElement;
      const atBottom =
        target.scrollTop + target.clientHeight >= target.scrollHeight - 1;

      if (atBottom && !loading.value) {
        await loadMore();
      }
    };
    const loadMore = async () => {
      if (ordersPagination.value.hasPages) {
        const { pageSize } = state.defaultParams;

        if (params.value?.page) {
          params.value.page += 1;
          params.value.pageSize = pageSize;
        } else {
          params.value.page = 1;
          params.value.pageSize = pageSize;
        }

        try {
          await fetchOrders(mode.value, false);
        } catch (e) {
          console.error("loadMore error", e);
        }
      }
    };

    // modal
    const show = computed(() => {
      return modal.orders;
    });
    const handleOpen = async (orderId: string) => {
      try {
        await storeFetchOrder(orderId);
        handleChangeMode();
        handleClose();

        openModal("order");
      } catch (e) {
        console.error("handleOpen error", e);
      }
    };
    const handleBack = () => {
      handleClose();
      openModal("profile");
    };
    const handleClose = () => {
      closeModal("orders");
    };

    watch(
      () => route.query,
      ({ mode }) => {
        if (mode) {
          handleChangeMode(Number(mode) as Mode);
        }
      },
      { immediate: true }
    );
    watch(
      () => show.value,
      async (status: boolean) => {
        if (status) {
          await fetchOrdersCount();

          if (!orders.value.length) {
            handleChangeMode();
          }
        }
      }
    );
    watch(
      () => mode.value,
      async (m: Mode) => {
        if (m) {
          setDefaultParams();
          await fetchOrders(m);
        }
      }
    );

    return {
      mode,
      modeTitle,
      handleChangeMode,

      loading,
      orders,
      ordersCount,
      totalCount,
      handleScroll,

      // modal
      show,
      handleOpen,
      handleClose,
      handleBack,
      closeAllModals,
    };
  },
});
</script>