export function usePhoneNumberFormatter() {
    const formatPhoneNumber = (phone: string): string => {
        const pattern = /^(\+7)([0-9X]{3})([0-9X]{3})([0-9X]{2})([0-9X]{2})$/;
        if (!pattern.test(phone)) {
            // console.error('Неверный формат номера телефона');
            return phone;
        }

        return phone.replace(pattern, '$1 $2 $3 $4 $5');
    };

    return {
        formatPhoneNumber
    };
}