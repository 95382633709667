<template>
  <Transition>
    <div
      v-if="show"
      key="modal-profile"
      class="popup-container"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title">
          <div class="inner">
            <h2>{{ user.firstName || "Профиль" }}</h2>
            <div class="subtitle">
              {{ user?.email }}
              <template>
                <!-- раньше отображали номер -->
                {{ formatPhoneNumber(user?.phoneNumber || "+7XXXXXXXXXX") }}
              </template>
            </div>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <div class="lk-content">
            <ul class="lk-menu">
              <li>
                <a href="#" @click.prevent="handleOpen('orders')">
                  <div class="icon">
                    <img
                      width="24"
                      height="24"
                      src="/images/layout-list.svg"
                      alt=""
                    />
                  </div>
                  <p>Заказы</p>
                  <div v-if="totalCount" class="count">{{ totalCount }}</div>
                  <i class="arrow" />
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="handleOpen('address')">
                  <div class="icon">
                    <img
                      width="24"
                      height="24"
                      src="/images/truck.svg"
                      alt=""
                    />
                  </div>
                  <p>Адреса доставки</p>
                  <i class="arrow" />
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="handleOpen('settings')">
                  <div class="icon">
                    <img
                      width="24"
                      height="24"
                      src="/images/settings.svg"
                      alt=""
                    />
                  </div>
                  <p>Настройки</p>
                  <i class="arrow" />
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="handleOpen('feedback')">
                  <div class="icon">
                    <img width="24" height="24" src="/images/mail.svg" alt="" />
                  </div>
                  <p>Связаться с нами</p>
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="handleLogout">
                  <div class="icon">
                    <img
                      width="24"
                      height="24"
                      src="/images/log-out.svg"
                      alt=""
                    />
                  </div>
                  <p>Выйти</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useAuthStore } from "~/store/auth";
import { useModalStore } from "~/store/modal";
import { useProfileStore } from "~/store/profile";

type ProfileModals = "profile" | "orders" | "address" | "settings" | "feedback";

export default defineComponent({
  setup() {
    const store = {
      auth: useAuthStore(),
      modal: useModalStore(),
      profile: useProfileStore(),
    };
    const { user, totalCount } = storeToRefs(store.profile);

    // auth/user
    const handleLogout = async () => {
      await store.auth.logout();
      handleClose();
    };

    // modal
    const show = computed(() => {
      const { modal } = store.modal;
      return modal.profile;
    });
    const handleOpen = (modal: ProfileModals) => {
      store.modal.closeModal("profile");
      store.modal.openModal(modal);
    };
    const handleClose = () => {
      store.modal.closeModal("profile");
    };

    watch(
      () => show.value,
      async (status: boolean) => {
        if (status) await store.profile.fetchOrdersCount();
      },
      { immediate: true }
    );

    const { formatPhoneNumber } = usePhoneNumberFormatter();

    return {
      // auth/user
      user,
      totalCount,
      handleLogout,

      // modal
      show,
      handleOpen,
      handleClose,

      // composables
      formatPhoneNumber,
    };
  },
});
</script>

<style scoped>
button:disabled {
  pointer-events: none;
  opacity: 0.7;
}
</style>