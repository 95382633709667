<template>
  <Transition>
    <div v-if="show" key="modal-checkout" class="popup-container" @click.self="handleClose">
      <div class="popup-content">
        <div class="popup-title">
          <a href="#" class="link-back" :class="{ disabled: loading }" @click.prevent="handleBack">
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <h2>Ваш заказ</h2>
            <div v-if="cartPartner?.id" class="seller">
              <img width="16" height="16" src="/images/store.svg" alt="">
              {{ cartPartner?.title || cartPartner?.slug }}
            </div>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <form action="#" class="lk-ordering-form simple-form" style="position: relative"
            @submit.prevent="handleSubmit">
            <Loader v-if="loading" />
            <div class="lk-ordering-products">
              <div class="products-list">
                <a v-for="product in splicedProducts" :key="product.id" href="#" class="image"
                  @click.prevent="handleOpen">
                  <CommonImage :data="product.mainImage" :height="64" :width="64" />
                </a>
                <a v-if="cart.products.length > lengthLimit" href="#" class="more-image" :class="{ disabled: loading }"
                  @click.prevent="handleOpen">
                  +{{ cart.products.length - lengthLimit || 0 }}
                </a>
              </div>
            </div>
            <h4>Способ оплаты</h4>
            <div class="lk-radio-menu">
              <div class="lk-radio-block">
                <label>
                  <input v-model="paymentType" :value="10" :disabled="loading" type="radio" name="pay" />
                  <i />
                  <span>Наличными при получении</span>
                </label>
              </div>
              <div class="lk-radio-block">
                <label>
                  <input v-model="paymentType" :value="20" :disabled="loading" type="radio" name="pay" />
                  <i />
                  <span>Переводом при получении</span>
                </label>
              </div>
            </div>
            <h4>Адрес доставки</h4>
            <div v-if="addresses?.length" class="title-note">
              Выберите из предложенных или создайте новый ниже
            </div>
            <div v-if="addresses?.length" class="lk-radio-menu">
              <div v-for="{ address, id } in addresses" :key="id" class="lk-radio-block">
                <label>
                  <input v-model="addressID" :value="id" :disabled="loading" type="radio" name="address"
                    @change="handleAddressChange(id)" />
                  <i />
                  <FilterTextProcessing :text="address" />
                </label>
              </div>
            </div>
            <div class="input-block">
              <CommonTextarea v-model="address" :disabled="loading" placeholder="Введите новый адрес доставки" />
            </div>
            <button type="button" class="btn empty-btn" :disabled="loading || address?.length < 5"
              @click.prevent="handleAddressAdd">
              Сохранить адрес
            </button>
            <div class="input-block">
              <CommonTextarea v-model="comment" :disabled="loading" :max-length="1024"
                placeholder="Комментарий к заказу (не обязательно)" />
            </div>
            <Transition mode="out-in" :duration="{ enter: 300, leave: 0 }">
              <div v-if="cart.products.length && costRemaining > 0" class="more-products">
                Для оформления заказа добавьте товаров еще на
                {{ priceFormat(costRemaining) }}
              </div>
            </Transition>
            <div class="total-price">
              Итого <span>{{ priceFormat(cart.totalCost) }}</span>
            </div>
            <button type="submit" class="btn" :disabled="submitDisabled">
              Отправить заказ
            </button>
          </form>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useCartStore } from "~/store/cart";
import { useErrorStore } from "~/store/error";
import { useModalStore } from "~/store/modal";
import { useProfileStore } from "~/store/profile";

interface CheckoutForm {
  address: string;
  addressID: string;
  comment: string;
  paymentType: number;
  [key: string]: string | number;
}

export default defineComponent({
  setup() {
    // store
    const store = {
      cart: useCartStore(),
      error: useErrorStore(),
      modal: useModalStore(),
      profile: useProfileStore(),
    };
    const { syncCart } = store.cart;
    const {
      addAddress,
      createOrder,
      fetchAddresses,
      fetchOrder,
      saveAddressToLocalStorage,
      updateBody,
    } = store.profile;

    // data
    const {
      cart,
      cartPartner,
      hasMultiplePartners,
      limits,
      loading: cartLoading,
      compactedProducts,
      missingProducts,
    } = storeToRefs(store.cart);
    const {
      addresses,
      loading: profileLoading,
      savedAddress,
    } = storeToRefs(store.profile);

    const costRemaining = computed(() => {
      const totalCostRequirement = limits.value.minOrderTotalCost;
      return totalCostRequirement - cart.value.totalCost;
    });

    const lengthLimit = ref<number>(7);
    const splicedProducts = computed(() => {
      const products = [...cart.value.products];
      return products.splice(0, lengthLimit.value);
    });

    // form
    const form = reactive<CheckoutForm>({
      address: "",
      addressID: "",
      comment: "",
      paymentType: 10,
    });
    const clearForm = () => {
      for (const key in form) {
        if (form.hasOwnProperty(key) && key !== "paymentType") {
          form[key] = "";
        }
      }
      form.paymentType = 10;
    };
    const getAddressFromLocalStorage = () => {
      form.addressID = savedAddress.value;
    };
    const handleAddressAdd = async () => {
      const { address } = form;

      if (address?.trim()?.length) {
        try {
          updateBody({ address }, "address");
          const { id } = await addAddress();

          form.address = "";
          handleAddressChange(id);
          getAddressFromLocalStorage();
        } catch (e) {
          console.error("handleAdd error", e);
        }
      }
    };
    const handleAddressChange = (id: string) => {
      saveAddressToLocalStorage(id);
    };
    const handleSubmit = async () => {
      if (hasMultiplePartners.value) {
        store.error.setCustomError("Ошибка: Невозможно сформировать заказ, так как в корзине присутствуют товары от нескольких разных партнеров. Пожалуйста, удалите товары от одного из партнеров и повторите попытку.")
        
        return;
      }

      try {
        // синхрониризуем данные корзины
        await syncCart();

        // формируем заказ, если нет отсутствующих продуктов
        if (!missingProducts.value?.length) {
          const body = {
            addressId: form.addressID,
            paymentType: form.paymentType,
            products: compactedProducts.value,
            ...(form.comment ? { comment: form.comment } : null),
          };
          updateBody(body, "order");
          const { id } = await createOrder();
          // подгружаем данные для отображения модалки заказа
          await fetchOrder(id);
          handleClose();
          openModal("order");
        }
      } catch (e) {
        console.error("handleSubmit error", e);
      }
    };

    // loading
    const loading = computed(() => {
      return cartLoading.value || profileLoading.value;
    });
    const submitDisabled = computed(() => {
      return (
        loading.value ||
        !form.addressID ||
        !form.paymentType ||
        costRemaining.value > 0
      );
    });

    // modal
    const { modal, openModal, openMobileModal, closeModal, closeAllModals } =
      store.modal;
    const show = computed(() => {
      return modal.checkout;
    });
    const handleOpen = () => {
      openModal("checkoutDetail");
    };
    const handleBack = () => {
      closeModal("checkout");
      openMobileModal("cart");
    };
    const handleClose = () => {
      closeModal("checkout");
    };

    watch(
      () => show.value,
      async (status: boolean) => {
        if (status) {
          await fetchAddresses();
          getAddressFromLocalStorage();
        } else {
          clearForm();
        }
      }
    );
    watch(
      () => cart.value.products.length,
      async (length: number) => {
        if (show.value && !length) {
          closeAllModals();
        }
      }
    );

    const { priceFormat } = usePriceFormat();

    return {
      // form
      ...toRefs(form),
      handleAddressAdd,
      handleAddressChange,
      handleSubmit,

      // data
      addresses,
      cart,
      cartPartner,
      costRemaining,
      lengthLimit,
      splicedProducts,
      loading,
      submitDisabled,

      // modal
      show,
      handleOpen,
      handleBack,
      handleClose,
      closeAllModals,

      priceFormat,
    };
  },
});
</script>

<style scoped>
.more-products {
  text-align: center;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #ff7043;
  line-height: 1.35;
}
</style>