<template>
  <ModalProfileMenu />
  <ModalProfileOrders />
  <ModalProfileAddress />
  <ModalProfileSettings />
  <ModalProfileFeedback />

  <Transition>
    <div v-show="hideProfileOrderModal">
      <ModalProfileOrder />
    </div>
  </Transition>
</template>

<script lang="ts">
import { useModalStore } from "~/store/modal";

export default defineComponent({
  setup() {
    const { modal } = useModalStore();

    const hideProfileOrderModal = computed(() => {
      return !modal.outstock && !modal.product;
    });

    return {
      hideProfileOrderModal,
    };
  },
});
</script>
