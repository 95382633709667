<template>
  <div class="container">
    <Header />

    <section class="main-section">
      <CategorySidebar />
      <div class="main-content">
        <slot />
        <Cookie />
      </div>
      <Cart />
    </section>

    <Modals />
    <client-only>
      <TransitionGroup>
        <!-- Затемнение -->
        <div v-if="show.general" key="overlay" class="popup-overlay" />
        <!-- Затемнение мобильное -->
        <div
          v-if="show.mobile && !show.general"
          key="mobile-overlay"
          class="mobile-overlay"
          @click.self="closeAllMobileModals"
        />
      </TransitionGroup>
    </client-only>
  </div>
</template>

<script lang="ts">
import { useModalStore } from "~/store/modal";

export default defineComponent({
  setup() {
    const { modal, mobileModal, closeAllMobileModals } = useModalStore();

    const show = computed(() => {
      return {
        general: Object.values(modal).includes(true),
        mobile: Object.values(mobileModal).includes(true),
      };
    });

    watchEffect(async () => {
      if (process.client) {
        if (show.value.general || show.value.mobile) {
          document.body.classList.toggle("modal-open", true);

          await new Promise((resolve) => setTimeout(resolve, 500));
          document.body.classList.add("ready");
        } else {
          document.body.classList.toggle("modal-open", false);
          document.body.classList.remove("ready");
        }
      }
    });

    return {
      show,
      closeAllMobileModals,
    };
  },
});
</script>

<style lang="less">
.v-enter-active,
.v-leave-active {
  transition: opacity 250ms linear;
}
.v-enter-from,
.v-leave-to {
  opacity: 0;
}

body {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));

  &.modal-open {
    overflow: hidden;
    transition: none;
  }
  &.ready {
    .v-enter-active,
    .v-leave-active,
    .v-enter-from,
    .v-leave-to {
      transition: none;
    }
  }
}

.btn:disabled {
  filter: opacity(0.7);
  pointer-events: none;
}

.disabled {
  pointer-events: none;
}

.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: -1;
}

.counting > *,
.add-minus,
.add-plus {
  user-select: none;
}

footer {
  position: relative;
  z-index: 1;
}

/*
@media screen and (max-width: 1019.98px) {
  .v-enter-active,
  .v-leave-active,
  .v-enter-from,
  .v-leave-to {
    transition: none;
  }
}
*/
</style>

<style scoped>
.mobile-overlay {
  display: block;
}
</style>