<template>
  <Transition>
    <div
      v-if="show"
      key="modal-otp"
      class="popup-container"
      style="z-index: 1001"
      @click.self="handleCloseAll"
    >
      <div class="popup-content">
        <div class="popup-title">
          <a href="#" class="link-back" @click.prevent="handleClose">
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <h2>Вход</h2>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <Loader v-if="loading" />
          <div class="lk-content">
            <form
              action="#"
              class="popup-form simple-form"
              @submit.prevent="handleSubmit"
            >
              <div class="enter-code">
                <CommonOtpInput v-model="otp" :disabled="loading" />
              </div>
              <div>
                <CommonOtpTimer
                  :duration="duration"
                  :loading="loading"
                  @resendCode="handleResend"
                />
              </div>
              <button
                type="submit"
                class="btn"
                :disabled="otpMerged?.length !== 4 || loading"
              >
                Войти
              </button>
            </form>
          </div>
        </div>
        <div class="enter-note">
          Продолжая авторизацию, вы соглашаетесь с
          <a href="#">политикой конфиденциальности</a>,
          <a href="#">условиями сервиса</a> и
          <a href="#">условиями продажи товаров</a>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useAuthStore } from "~/store/auth";
import { useModalStore } from "~/store/modal";

export default defineComponent({
  setup() {
    const store = {
      auth: useAuthStore(),
      modal: useModalStore(),
    };
    const show = computed(() => {
      const { modal } = store.modal;
      return modal.otp;
    });
    const handleClose = () => {
      store.modal.closeModal("otp");
    };
    const handleCloseAll = () => {
      store.modal.closeAllModals();
    };

    // auth
    const otp = ref<string[]>(["", "", "", ""]);
    const otpMerged = computed(() => {
      return otp.value.join("");
    });
    const loading = computed(() => {
      return store.auth.loading;
    });

    const handleClear = () => {
      otp.value.fill("");
    };

    const handleSubmit = async () => {
      if (otp.value.length === 4) {
        try {
          store.auth.updateBody({
            code: Number(otpMerged.value),
          });
          const data = await store.auth.login();
          handleClear();

          if (data?.accessToken && data?.refreshToken) {
            store.modal.closeAllModals();
          } else if (data?.code === 4242) {
            store.modal.closeAllModals("error");
          }
        } catch (e) {
          console.error("handleSubmit error", e);
        }
      }
    };

    // timer
    const duration = ref<number>(60 * 5);
    const handleResend = async () => {
      try {
        const status = await store.auth.sendCode();

        if (status === 1) {
          duration.value = 60 * 5;
        } else if (status === 4242 || status === 4146) {
          handleClose();
        }
      } catch (e: any) {
        console.error("handleSubmit error", e);
      }
    };

    return {
      // modal
      show,
      handleClose,
      handleCloseAll,

      // auth
      otp,
      otpMerged,
      loading,
      duration,
      handleSubmit,
      handleResend,
    };
  },
});
</script>

<style scoped>
button:disabled {
  pointer-events: none;
  opacity: 0.7;
}
</style>