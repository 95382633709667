<template>
  <Transition>
    <div
      v-if="show"
      key="modal-settings"
      class="popup-container"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title">
          <a
            href="#"
            class="link-back"
            @click.prevent="mode ? handleChangeMode() : handleBack()"
          >
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <h2>Настройки</h2>
            <div class="subtitle">
              <template v-if="mode === 'name'">
                {{ user?.firstName ? "Изменить имя" : "Добавить имя" }}
              </template>
              <template v-else-if="mode === 'phone'">
                {{
                  user?.phoneNumber
                    ? "Изменить номер телефона"
                    : "Добавить номер телефона"
                }}
              </template>
              <template v-else-if="mode === 'telegram'">
                {{ user?.tgId ? "Отвязать" : "Привязать" }} telegram bot
              </template>
              <template v-else>Профиль</template>
            </div>
          </div>
          <CommonCloseButton />
        </div>

        <div class="popup-description">
          <Loader v-if="loading" />
          <template v-if="mode === 'name'">
            <div class="lk-content">
              <form
                action="#"
                class="popup-form simple-form"
                @submit.prevent="handleUserUpdate"
              >
                <div class="input-block">
                  <input
                    v-model="firstName"
                    type="text"
                    placeholder="Введите ваше имя"
                    :maxlength="50"
                    :disabled="loading"
                    @keydown="handlePrevent"
                  />
                </div>
                <button
                  type="submit"
                  class="btn"
                  :disabled="firstName?.trim()?.length < 2 || loading"
                >
                  Сохранить
                </button>
              </form>
            </div>
          </template>
          <template v-else-if="mode === 'phone'">
            <div class="lk-content">
              <form
                action="#"
                class="popup-form simple-form"
                @submit.prevent="handleUserUpdate"
              >
                <div
                  v-if="user?.phoneNumber"
                  class="input-label"
                  style="font-size: 14px; color: inherit"
                >
                  Текущий номер телефона <br />
                  {{ formatPhoneNumber(user?.phoneNumber || "+7XXXXXXXXXX") }}
                </div>
                <div class="input-block">
                  <input
                    v-model="phoneNumber"
                    type="tel"
                    v-maska
                    data-maska="+7 (###) ### ## ##"
                    placeholder="+7 (XХХ) ХХХ ХХ ХХ"
                    :disabled="loading"
                  />
                </div>
                <button
                  type="submit"
                  class="btn"
                  :disabled="phoneNumber?.length !== 18 || loading"
                >
                  Сохранить
                </button>
              </form>
            </div>
          </template>
          <template v-else-if="mode === 'telegram'">
            <div v-if="user?.tgId" class="lk-content">
              <div class="telegram-out">
                <p><strong>Telegram-bot привязан к:</strong></p>
                <ul class="telegram-out-list">
                  <li v-if="user?.tgUsername || user?.tgId">
                    <span>Username:</span>
                    <span>
                      <a
                        v-if="user.tgUsername"
                        :href="`https://t.me/${user.tgUsername}`"
                      >
                        @{{ user.tgUsername }}
                      </a>
                      <template v-else>@{{ user.tgId }}</template>
                    </span>
                  </li>
                  <li v-if="user?.firstName">
                    <span>Имя:</span>
                    <span>{{ user.firstName }}</span>
                  </li>
                </ul>
                <div class="enter-note">
                  <p class="lk-text">
                    Отвязав аккаунт от телеграм бота вы больше не сможете:
                  </p>
                  <ul class="lk-list">
                    <li>Получать оповещения о событиях в личном кабинете</li>
                    <li>Получать оповещения о новинках и акциях на сайте</li>
                    <li>Принимать участие в розыгрышах и получать бонусы</li>
                  </ul>
                </div>
                <a
                  href="#"
                  class="btn"
                  :class="{ disabled: loading }"
                  @click.prevent="handleDebind()"
                >
                  Отвязать
                </a>
              </div>
            </div>
            <div v-else-if="tgBindStatus">
              <div class="telegram-add">
                <p style="margin-bottom: 2rem">
                  Мы отправили ссылку на бота
                  <br />
                  Если Вы привязали аккаунт к боту, нажмите кнопку ниже
                </p>
                <a
                  href="#"
                  class="btn"
                  :class="{ disabled: loading }"
                  @click.prevent="handleRefresh()"
                >
                  Проверить
                </a>
              </div>
            </div>
            <div v-else class="lk-content">
              <div class="telegram-add">
                <div class="enter-note">
                  <p class="lk-text">
                    Привяжите ваш аккаунт телеграм к боту Product-express чтобы:
                  </p>
                  <ul class="lk-list">
                    <li>Получать оповещения о событиях в личном кабинете</li>
                    <li>Получать оповещения о новинках и акциях на сайте</li>
                    <li>Принимать участие в розыгрышах и получать бонусы</li>
                    <li>Отвязать аккаунт от бота можно в любой момент</li>
                  </ul>
                </div>
                <a
                  href="#"
                  class="btn"
                  :class="{ disabled: loading }"
                  @click.prevent="handleBind()"
                >
                  Привязать
                </a>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="lk-content">
              <ul class="lk-menu">
                <li>
                  <a
                    href="#"
                    :class="{ disabled: loading }"
                    @click.prevent="handleChangeMode('phone')"
                  >
                    <p>
                      <template v-if="user?.phoneNumber">
                        {{
                          formatPhoneNumber(user?.phoneNumber || "+7XXXXXXXXXX")
                        }}
                      </template>
                      <template v-else>Добавить номер телефона</template>
                    </p>
                    <i class="arrow" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    :class="{ disabled: loading }"
                    @click.prevent="handleChangeMode('name')"
                  >
                    <p>
                      {{ user?.firstName ? "Изменить имя" : "Добавить имя" }}
                    </p>
                    <i class="arrow" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    :class="{ disabled: loading }"
                    @click.prevent="handleChangeMode('telegram')"
                  >
                    <p>
                      {{ user?.tgId ? "Отвязать" : "Привязать" }}
                      telegram-bot
                    </p>
                    <i class="arrow" />
                  </a>
                </li>
              </ul>
            </div>
          </template>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useErrorStore } from "~/store/error";
import { useModalStore } from "~/store/modal";
import { useProfileStore } from "~/store/profile";

type Mode = "name" | "telegram" | "phone" | null;

interface SettingForm {
  firstName: string;
  lastName: string;
  patronymic: string;
  phoneNumber: string;
  tgBindStatus: boolean;
}

export default defineComponent({
  setup() {
    // store
    const store = {
      modal: useModalStore(),
      profile: useProfileStore(),
      error: useErrorStore(),
    };
    const { modal, openModal, closeModal, closeAllModals } = store.modal;
    const {
      clearUser,
      clearBody,
      fetchUser,
      updateUser,
      updateBody,
      telegramBind,
      telegramDebind,
    } = store.profile;
    const { user, loading } = storeToRefs(store.profile);

    // form
    const form = reactive<SettingForm>({
      firstName: "",
      lastName: "",
      patronymic: "",
      phoneNumber: "",
      tgBindStatus: false,
    });
    const handlePrevent = (event: KeyboardEvent) => {
      // блокируем возможность юзать пробел вначале
      if (event.key === " " && !form.firstName) {
        event.preventDefault();
      }
    };

    // update user data
    const handleUserUpdate = async () => {
      const { firstName, phoneNumber } = form;

      if (mode.value === "name") {
        if (firstName?.trim()?.length) {
          try {
            clearBody();
            await new Promise((resolve) => setTimeout(resolve, 250));

            updateBody({ firstName }, "user");
            const response = await updateUser();

            if (response?.id) {
              handleChangeMode();
            } else {
              form.firstName = user.value?.firstName || "";
            }
          } catch (e) {
            form.firstName = user.value?.firstName || "";
            console.error("handleUserUpdate error", e);
          }
        }
      } else if (mode.value === "phone") {
        const regex = {
          phone: /^\+79\d{9}$/,
          filter: /[^\d+]/g,
        };
        const filteredNumber = phoneNumber.replace(regex.filter, "");

        if (regex.phone.test(filteredNumber)) {
          try {
            clearBody();
            await new Promise((resolve) => setTimeout(resolve, 250));

            updateBody({ phoneNumber: filteredNumber }, "user");
            const response = await updateUser();

            if (response?.id) {
              handleChangeMode();
            } else {
              form.phoneNumber = user.value?.phoneNumber || "";
            }
          } catch (e) {
            form.phoneNumber = user.value?.phoneNumber || "";
            console.error("handleUserUpdate error", e);
          }
        } else {
          await new Promise((resolve) => setTimeout(resolve, 500));
          // инициируем пользовательскую ошибку
          const error = {
            response: {
              data: {
                code: 400,
                message:
                  "Номер должен соответствовать формату телефонного номера +7 (9ХХ) ХХХ ХХ ХХ",
                statusCode: 400,
              },
              status: 400,
              statusText: "Custom error",
            },
            message: "",
          };
          store.error.setError(error);
          form.phoneNumber = "";

          // console.log("Номер телефона не соответствует формату /^+79d{9}$/");
        }
      }
    };

    // media
    const isMobile = ref(false);
    const updateIsMobile = () => {
      isMobile.value = window.innerWidth <= 767.98;
    };
    onMounted(async () => {
      updateIsMobile();
      window.addEventListener("resize", updateIsMobile);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", updateIsMobile);
    });
    // telegram bind
    const childWindow = ref<Window | null>(null);
    const openWindowAndTrack = (link: string) => {
      childWindow.value = window.open(link, "_blank");

      const intervalId = setInterval(() => {
        if (childWindow.value?.closed) {
          handleRefresh();
          form.tgBindStatus = false;
          clearInterval(intervalId);
        }
      }, 1000);

      onUnmounted(() => {
        clearInterval(intervalId);
      });
    };

    const handleBind = async () => {
      try {
        const telegramLink = await telegramBind();

        if (typeof telegramLink === "string") {
          form.tgBindStatus = true;
          if (isMobile.value) {
            window.open(telegramLink, "_blank");
          } else {
            openWindowAndTrack(telegramLink);
          }
        }
      } catch (e) {
        console.error("handleBind error", e);
      }
    };
    const handleDebind = async () => {
      try {
        await telegramDebind();
        form.tgBindStatus = false;
      } catch (e) {
        console.error("handleDebind error", e);
      }
    };
    const handleRefresh = async () => {
      clearUser();
      const { tgId } = await fetchUser();

      if (tgId) {
        form.tgBindStatus = false;
      }
    };

    const mode = ref<Mode>(null);
    const handleChangeMode = (m: Mode = null) => {
      mode.value = m;
      if (m === null) form.tgBindStatus = false;
    };

    // modal
    const show = computed(() => {
      return modal.settings;
    });
    const handleClose = () => {
      form.firstName = user.value?.firstName || "";
      form.phoneNumber = user.value?.phoneNumber || "";

      closeModal("settings");
    };
    const handleBack = () => {
      handleClose();
      openModal("profile");
    };

    watch(
      () => show.value,
      async (status: boolean) => {
        if (!status) {
          mode.value = null;
          form.tgBindStatus = false;
        }
      }
    );
    // initial values
    watch(
      () => user.value.firstName,
      async (name: string) => {
        if (name) form.firstName = name;
        else form.firstName = "";
      },
      { immediate: true }
    );
    watch(
      () => user.value.phoneNumber,
      async (number: string) => {
        if (number) {
          form.phoneNumber = number;
        } else {
          form.phoneNumber = "";
        }
      },
      { immediate: true }
    );

    const { formatPhoneNumber } = usePhoneNumberFormatter();

    return {
      ...toRefs(form),
      handlePrevent,

      loading,
      handleUserUpdate,

      handleBind,
      handleDebind,
      handleRefresh,

      // profile
      user,
      // mode
      mode,
      handleChangeMode,

      // modal
      show,
      handleClose,
      handleBack,
      closeAllModals,

      formatPhoneNumber,
    };
  },
});
</script>

<style scoped>
.enter-note {
  margin: 0;
}
.lk-text {
  text-align: left;
  margin-bottom: 1rem;
}
.lk-list {
  line-height: 1.25;
  text-align: left;
  list-style: unset;
  list-style-position: inside;
  margin: 0 auto 1.5rem;
}
.lk-list li:not(:last-child) {
  margin-bottom: 0.125rem;
}
</style>