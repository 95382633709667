<template>
  <Transition>
    <div v-if="show" class="popup-container" @click.self="handleClose">
      <div class="popup-content">
        <div class="popup-title no-text">
          <div class="close-modal" @click="handleClose">
            <img width="20" height="20" src="/images/close.svg" alt="" />
          </div>
        </div>
        <div class="popup-description">
          <div class="popup-empty-page">
            <div class="image">
              <img
                v-if="error.status"
                width="88"
                height="88"
                src="/images/loader.svg"
                alt=""
              />
              <img
                v-else
                width="88"
                height="88"
                src="/images/frown.svg"
                alt=""
              />
            </div>
            <h2>{{ error.status || 404 }}</h2>
            <p v-html="error.message || `Здесь ничего интересного нет...`" />
            <p v-if="error.status === 0">
              <strong>
                {{ "А на главной ЕСТЬ!" }}
              </strong>
            </p>

            <a
              v-if="error.status"
              href="#"
              class="btn"
              @click.prevent="handleClose"
            >
              Закрыть
            </a>
            <NuxtLink v-else to="/" class="btn" @click.prevent="closeAllModals">
              <img width="20" height="20" src="/images/drumstick.svg" alt="" />
              На главную
            </NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useErrorStore } from "~/store/error";
import { useModalStore } from "~/store/modal";

export default defineComponent({
  setup() {
    const store = {
      error: useErrorStore(),
      modal: useModalStore(),
    };
    // error
    const { error } = storeToRefs(store.error);

    // modal
    const { modal } = storeToRefs(store.modal);
    const show = computed(() => {
      return modal.value.error;
    });
    const handleClose = () => {
      store.modal.closeModal("error");
      store.error.clearError();
    };
    const { closeAllModals } = store.modal;

    return {
      // error
      error,

      // modal
      show,
      handleClose,
      closeAllModals,
    };
  },
});
</script>
