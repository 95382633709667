<template>
    <Transition>
        <div v-if="show" key="modal-signin" class="popup-container" @click.self="handleClose">
            <div class="popup-content">
                <div class="popup-title">
                    <div class="inner">
                        <h2>Очистить корзину</h2>
                    </div>
                    <CommonCloseButton />
                </div>
                <div class="popup-description">
                    <div class="lk-content">
                        <div class="address-remove">
                            <p>Вы уверены?</p>
                            <div class="flex-block">
                                <a href="#" class="btn empty-btn" :class="{ disabled: loading }"
                                    @click.prevent="handleClear">
                                    Да
                                </a>
                                <a href="#" class="btn" :class="{ disabled: loading }"
                                    @click.prevent="handleClose">
                                    Нет
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>


<script lang="ts" setup>
import { useCartStore } from "~/store/cart";
import { useModalStore } from "~/store/modal";

const store = {
    cart: useCartStore(),
    modal: useModalStore(),
};
const { clearCart } = store.cart;
const { loading } = storeToRefs(store.cart);

const handleClear = async () => {
    loading.value = true;

    clearCart()
    await new Promise((resolve) => setTimeout(resolve, 250));
    handleClose()

    loading.value = false;
}

const { modal } = storeToRefs(store.modal);
const show = computed(() => {
    return modal.value.clearCart;
});
const handleClose = () => {
    store.modal.closeModal("clearCart");
};
</script>
