<template>
  <TransitionGroup>
    <div v-show="hideAdminModals" key="modal-admin">
      <ModalAdminMenu />
      <ModalAdminOrders />
      <ModalAdminOrder />
      <ModalAdminCategories />
      <ModalAdminProducts />
      <ModalAdminProductEdit />
    </div>
  </TransitionGroup>

  <ModalAdminProduct />
</template>

<script lang="ts">
import { useModalStore } from "~/store/modal";

export default defineComponent({
  setup() {
    const { modal } = useModalStore();

    const hideAdminModals = computed(() => {
      return !modal.openProduct || !modal.adminOrder;
    });

    return {
      hideAdminModals,
    };
  },
});
</script>
