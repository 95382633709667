<template>
  <div class="enter-timer">
    <p v-if="!timerExpired">
      Получить новый код можно через <br /><b>{{ timeLeft }}</b> секунд
    </p>
    <button v-else :disabled="disabled" @click.prevent="restartTimer">
      Отправить код повторно
    </button>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: Number,
      default: 5 * 60, // 5 min
    },
  },
  emits: ["resendCode"],
  setup(props, { emit }) {
    const currentTime = ref(0);
    let timer: number | null = null;

    const timeLeft = computed(() => {
      return currentTime.value;
    });
    const timerExpired = computed(() => {
      return currentTime.value <= 0;
    });

    // инициализация таймера
    const startTimer = () => {
      currentTime.value = props.duration;

      if (currentTime.value > 0) {
        timer = window.setInterval(() => {
          currentTime.value -= 1;
          if (currentTime.value <= 0) {
            if (timer !== null) {
              clearInterval(timer);
            }
          }
        }, 1000);
      }
    };

    // запуск таймера при инициализации компонента
    onMounted(() => {
      startTimer();
    });

    // перезапуск таймера по запросу
    const restartTimer = () => {
      if (timer !== null) {
        clearInterval(timer);
      }
      startTimer();
      emit("resendCode");
    };

    // отслеживание изменений пропса 'duration'
    watch(
      () => props.duration,
      () => {
        restartTimer();
      }
    );

    return {
      timeLeft,
      timerExpired,
      restartTimer,
    };
  },
});
</script>

<style scoped>
.enter-timer {
  text-align: center;
  line-height: 1.33;
  font-size: 13px;
  color: #0a0a0a;
  margin-bottom: 1rem;
}
.enter-timer button {
  font-weight: 700;
  cursor: pointer;
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
.enter-timer button:hover {
  text-decoration: underline;
  text-decoration-skip-ink: none;
}
.enter-timer button:disabled {
  pointer-events: none;
  opacity: 0.7;
}
</style>