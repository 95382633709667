<template>
  <aside class="right-aside" :style="{ display: show ? 'block' : 'none' }">
    <form class="right-aside-content cart-form simple-form" @submit.prevent="finalizeOrder">
      <div class="aside-title">
        <h2>Корзина</h2>
        <a v-if="cart.products?.length" href="?clear-cart" class="clear-cart" :class="{ disabled: loading }"
          @click.prevent="handleClear">
          Очистить корзину
        </a>
        <div class="close-modal" @click="handleClose">
          <img width="20" height="20" src="/images/close.svg" alt="" />
        </div>
      </div>

      <div v-if="cart.products?.length" class="cart-products-list">
        <TransitionGroup>
          <div v-for="product in cart.products" :key="product.id" class="cart-product">
            <a :href="`?product=${product.slug}`" class="image" @click.prevent="openProduct(product.slug)">
              <CommonImage :data="product.mainImage" :height="71" :width="71" />
            </a>
            <div class="inner">
              <div class="name">
                <a :href="`?product=${product.slug}`" :class="{ disabled: loading }"
                  @click.prevent="openProduct(product.slug)">
                  {{ product.title || "Неизвестный продукт" }}
                </a>
              </div>
              <div class="available">
                <img
                  width="16"
                  height="16"
                  :src="product.isWeight ? '/images/weigher.svg' : '/images/box.svg'"
                  alt=""
                />
                {{ !!product.priceKilo ? `${product.unit} / ${priceFormat(product.priceKilo || 0)} за кг` : product.unit || "0 г" }}
              </div>
              <div v-if="product.quantityStock" class="counting">
                <div class="product-amount">
                  <span class="qtyminus" :class="{ disabled: loading }" @click="removeFromCart(product.id)">
                    <img width="16" height="16" src="/images/minus.svg" alt="" />
                  </span>
                  <input v-maska data-maska="###" type="text" class="qty" :value="product.count || 0"
                    :disabled="loading || product.count >= product.quantityStock || product.count >= limits.maxProductCount"
                    @blur="handleInput($event, product)" />
                  <span v-if="
                    product.count < product.quantityStock &&
                    product.count < limits.maxProductCount
                  " class="qtyplus" :class="{
                    disabled: loading,
                  }" @click="addToCart(product)">
                    <img width="16" height="16" src="/images/plus.svg" alt="" />
                  </span>
                </div>
                <div class="price-block">
                  <div v-if="product.priceDiscount" class="old-price">
                    {{ priceFormat(product.cost) }}
                  </div>
                  <div class="price" :class="{ discount: product.priceDiscount }">
                    {{ priceFormat(product.priceDiscount ?? product.cost) }}
                  </div>
                </div>
              </div>
              <div v-else class="counting">
                <div class="not-available">Нет в наличии</div>
              </div>
            </div>
            <a href="#" class="remove" :class="{ disabled: loading }" @click.prevent="removeFromCart(product.id, true)">
              <img width="20" height="20" src="/images/close.svg" alt="" />
            </a>
          </div>
        </TransitionGroup>
      </div>
      <template v-else>
        <div v-if="displayLoader" class="cart-products-list" style="min-height: 120px;">
          <Loader type="cart" />
        </div>
        <p v-else style="text-align: center; padding: 1rem 0">Корзина пуста.</p>
      </template>
      <div class="cart-bottom">
        <Transition mode="out-in" :duration="{ enter: 300, leave: 0 }">
          <div v-if="cart.products.length && costRemaining > 0" class="more-products">
            Для оформления заказа добавьте товаров еще на
            {{ priceFormat(costRemaining) }}
          </div>
        </Transition>
        <template v-if="cart.products?.length">
          <div class="total-price">
            Итого <span>{{ priceFormat(cart.totalCost) }}</span>
          </div>
          <button type="submit" class="btn" :disabled="costRemaining > 0">
            Продолжить
          </button>
        </template>
      </div>
      <a v-if="cart.products?.length" href="#" class="cart-seller" @click.prevent="setPartnerHandle">
        <img width="24" height="24" src="/images/store.svg" alt="" />
        <span style="text-align: center">
          {{ cartPartner?.slug ? `Все товары “${cartPartner!.title || cartPartner!.slug}”` : 'Все продавцы' }}
        </span>
        <i class="arrow" />
      </a>
    </form>
  </aside>

  <!-- Корзина мобильная -->
  <div class="mobile-cart" @click="handleOpen">
    <img width="22" height="22" src="/images/shopping-bag.svg" alt="" />
    <div class="price">{{ priceFormat(cart.totalCost) }}</div>
  </div>
</template>

<script lang="ts">
import { useStore } from "~/store";
import { useAuthStore } from "~/store/auth";
import { useCartStore } from "~/store/cart";
import { useModalStore } from "~/store/modal";
import { usePartnerStore } from "~/store/partners";
import { useProfileStore } from "~/store/profile";

import type { Partner, ProductCart } from "~/types/types";

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { metaData, replaceUrlWithoutNavigation } = useSeoTags();

    // store
    const store = {
      auth: useAuthStore(),
      cart: useCartStore(),
      main: useStore(),
      modal: useModalStore(),
      partner: usePartnerStore(),
      profile: useProfileStore(),
    };

    const { isAuthorized } = storeToRefs(store.auth);
    const { user } = storeToRefs(store.profile);

    const {
      addToCart,
      fetchCartLimits,
      loadCartFromLocalStorage,
      removeFromCart,
      setMissingProducts,
      syncCart,
      syncCartProducts,
    } = store.cart;
    const { openModal, openMobileModal, closeMobileModal, closeAllModals } =
      store.modal;

    // partner logic
    const { setPartner, clearPartner } = store.partner;
    const setPartnerHandle = () => {
      closeMobileModal('cart');

      if (cartPartner.value) {
        setPartner(cartPartner.value)
      } else {
        clearPartner()
      }
    }

    // modal status
    const show = computed(() => {
      return store.modal.mobileModal.cart;
    });
    // methods
    const handleOpen = () => {
      openMobileModal("cart");
    };
    const handleClose = () => {
      closeMobileModal("cart");
    };

    // cart
    const { cart, cartPartner, hasMultiplePartners, limits, loading, missingProducts } = storeToRefs(store.cart);

    const costRemaining = computed(() => {
      const totalCostRequirement = limits.value.minOrderTotalCost;
      return totalCostRequirement - cart.value.totalCost;
    });

    const fetchLimits = async () => {
      try {
        await fetchCartLimits();
      } catch (e) {
        console.error("fetchLimits error", e);
      }
    };
    const finalizeOrder = async () => {
      const { maxProductsLength, maxProductCount } = limits.value;
      /*
      const totalCount: number = cart.value.products.reduce(
        (accumulator, product) => {
          return accumulator + product.count;
        },
        0
      );
      */

      const lengthLessThanLimit =
        cart.value.products.length <= maxProductsLength;
      const countsLessThanLimit = cart.value.products.every(
        (product) =>
          product.count <= maxProductCount &&
          product.count <= product.quantityStock
      );

      if (isAuthorized.value) {
        loading.value = true;
        if (!user.value?.phoneNumber) {
          loading.value = false;
          openModal("phone");
        } else if (lengthLessThanLimit && countsLessThanLimit) {
          try {
            // (как починят сохранение index у корзины, можно вернуть) await syncCart();
            await new Promise((resolve) => setTimeout(resolve, 500));

            // открываем модалку формирования заказа, если нет отсутствующих продуктов
            if (!missingProducts.value?.length) {
              closeMobileModal("cart");
              openModal("checkout");
            }
          } catch (e) {
            console.error("finalizeOrder error", e);
          } finally {
            loading.value = false;
          }
        } else {
          try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            const missingProducts = cart.value.products.filter(
              (product) =>
                product.count >= maxProductCount &&
                product.count <= product.quantityStock
            );

            setMissingProducts(missingProducts);
          } catch (e) {
            console.error("finalizeOrder error", e);
          } finally {
            loading.value = false;
          }
        }
      } else {
        openModal("signin");
      }
    };
    const handleInput = async (e: InputEvent, product: ProductCart) => {
      const value = Number((e.target as HTMLInputElement).value);
      if (typeof value === "number") {
        loading.value = true;

        await new Promise((resolve) => setTimeout(resolve, 250));
        addToCart(product, value);

        loading.value = false;
      }
    };
    const openProduct = async (slug: string) => {
      try {
        if (slug) {
          await store.main.fetchProductByArg(slug);
          store.modal.openModal("product");

          /*
          const query = { ...route.query };
          query.product = slug;
          router.replace({ query });
          */

          const { product } = storeToRefs(store.main);
          if (product.value?.id) {
            useHead(metaData(product.value) as object);

            const { category } = product.value;
            const url = `${category?.slug ? `${category.slug}/` : ""}${slug}`;
            replaceUrlWithoutNavigation(url);
          }
        }
      } catch (e) {
        console.error("openProduct err", e);
      }
    };
    const handleClear = () => {
      openModal('clearCart');
    }

    const displayLoader = ref<boolean>(true);
    onMounted(async () => {
      await loadCartFromLocalStorage();

      if (hasMultiplePartners.value) {
        await handleClear();
      }

      try {
        await syncCart();
      } catch (e) {
        console.error("synchCart error", e);
      } finally {
        await new Promise((resolve) => setTimeout(resolve, 250));
        displayLoader.value = false;
      }

      const { partner } = route.query;
      if (!partner) {
        setPartnerHandle();
      }
    });

    // watchers
    watch(
      () => cart.value.products.length,
      async (length: number) => {
        if (length) await fetchLimits();
      }
    );
    // отобразить юзеру неактуальные товары (те у которых quantityStock < count)
    watch(
      () => missingProducts.value?.length,
      async (length: number) => {
        if (length) {
          closeAllModals(["order", "checkoutDetail"]);

          await new Promise((resolve) => setTimeout(resolve, 250));
          openModal("outstock");
        }
      },
      { immediate: true }
    );
    // feat (при обновлении города, обновляем список продуктов на актуальные для выбранного города)
    const { cityId } = storeToRefs(store.main);
    watch(
      () => cityId.value,
      async (id: string | null, prevId: string | null) => {
        if (prevId !== null && id !== prevId) {
          await syncCartProducts();
        }
      }
    );
    // feat (при смене партнера, обновить лимиты)
    watch(
      () => cartPartner.value,
      async (partner: Partner | null, prevPartner: Partner | null) => {
        if (prevPartner !== null && (partner?.id !== prevPartner?.id)) {
          limits.value.fetched = false;
          await fetchLimits();
        }
      }
    );
    // feat partner conflict = завершить заказ товаров продавца «partner»
    watch(
      () => route.query,
      async (query) => {
        if (query.action === "completeOrder") {
          const query = { ...route.query };
          delete query.action;
          router.replace({ query });

          await finalizeOrder();
          loading.value = false;
        }
      }
    );

    const { priceFormat } = usePriceFormat();

    return {
      isAuthorized,

      // modal
      show,
      handleOpen,
      handleClose,

      // cart
      cart,
      displayLoader,
      limits,
      loading,

      // partner
      cartPartner,
      setPartnerHandle,

      priceFormat,
      costRemaining,

      addToCart,
      removeFromCart,
      handleInput,
      finalizeOrder,
      openProduct,
      handleClear,
    };
  },
});
</script>

<style scoped>
.disabled {
  pointer-events: none;
}

.mobile-cart {
  cursor: pointer;
}

.image-empty {
  height: 70px;
  background: #f2f7f9;
}

.cart-product {
  display: flex;
  align-items: flex-start;
}

.cart-products-list {
  position: relative;
}

@media (max-width: 1019px) {
  .cart-products-list {
    min-height: 300px;
  }
}
</style>