<template>
  <Transition>
    <div
      v-if="show"
      key="modal-orders"
      class="popup-container"
      @click.self="handleChangeMode(), handleClose()"
    >
      <div class="popup-content">
        <div class="popup-title">
          <a
            href="#"
            class="link-back"
            :class="{ disabled: loading }"
            @click.prevent="mode ? handleChangeMode() : handleBack()"
          >
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <h2>Заказы</h2>
            <div class="subtitle">
              {{ modeTitle }}
              {{
                ordersCount?.[mode]?.count ? `(${ordersCount[mode].count})` : ""
              }}
            </div>
          </div>
          <CommonCloseButton @close="handleChangeMode" />
        </div>
        <template v-if="mode">
          <div class="popup-description" @scroll="handleScroll">
            <div
              class="lk-content"
              style="margin: 0"
              :style="!orders.length && loading ? '' : 'position: relative;'"
            >
              <Loader v-if="loading" />
              <ul v-if="orders.length" class="lk-orders-list">
                <li v-for="order in orders" :key="order.id">
                  <a
                    href="#"
                    class="lk-orders-link"
                    :class="{ disabled: loading }"
                    @click.prevent="handleOpen(order.id)"
                  >
                    <div class="number">
                      Заказ №{{ order?.serialNumber || 0 }}
                      <span v-if="order.user?.phoneNumber" class="phone">
                        ({{ order.user.phoneNumber }})
                      </span>
                    </div>
                    <div class="date">
                      от
                      {{
                        $dayjs(order.createDT || null).format(
                          "DD.MM.YYYY HH:mm"
                        )
                      }}
                    </div>
                    <div class="price">
                      Сумма {{ order.totalCost || 0 }} руб
                    </div>
                    <div v-if="order.messages" class="messages">
                      <img
                        width="24"
                        height="24"
                        src="/images/mail.svg"
                        alt=""
                      />
                      <div class="count">1</div>
                    </div>
                  </a>
                </li>
              </ul>
              <Loader v-else-if="loading" />
              <p v-else style="text-align: center">Заказы не найдены</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="popup-description">
            <div class="lk-content">
              <ul class="lk-menu">
                <li>
                  <a
                    href="#"
                    :class="{ disabled: !ordersCount[30]?.count || loading }"
                    @click.prevent="handleChangeMode(30)"
                  >
                    <p>На сборке</p>
                    <div class="orders">
                      {{ ordersCount[30]?.count || 0 }}
                    </div>
                    <div v-if="ordersCount[30]?.newCount" class="count">
                      {{ ordersCount[30]?.newCount }}
                    </div>
                    <i v-if="ordersCount[30]?.count" class="arrow" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    :class="{ disabled: !ordersCount[50]?.count || loading }"
                    @click.prevent="handleChangeMode(50)"
                  >
                    <p>В пути</p>
                    <div class="orders">
                      {{ ordersCount[50]?.count || 0 }}
                    </div>
                    <div v-if="ordersCount[50]?.newCount" class="count">
                      {{ ordersCount[50]?.newCount }}
                    </div>
                    <i v-if="ordersCount[50]?.count" class="arrow" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    :class="{ disabled: !ordersCount[100]?.count || loading }"
                    @click.prevent="handleChangeMode(100)"
                  >
                    <p>Доставлены</p>
                    <div class="orders">
                      {{ ordersCount[100]?.count || 0 }}
                    </div>
                    <div v-if="ordersCount[100]?.newCount" class="count">
                      {{ ordersCount[100]?.newCount }}
                    </div>
                    <i v-if="ordersCount[100]?.count" class="arrow" />
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    :class="{ disabled: !ordersCount[-100]?.count || loading }"
                    @click.prevent="handleChangeMode(-100)"
                  >
                    <p>Отменены</p>
                    <div class="orders">
                      {{ ordersCount[-100]?.count || 0 }}
                    </div>
                    <div v-if="ordersCount[-100]?.newCount" class="count">
                      {{ ordersCount[-100]?.newCount }}
                    </div>
                    <i v-if="ordersCount[-100]?.count" class="arrow" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useAdminStore } from "~/store/admin";
import { useModalStore } from "~/store/modal";

import type { OrderState } from "~/types/types";

type Mode = OrderState | null;

export default defineComponent({
  setup() {
    const route = useRoute();
    const router = useRouter();
    // store
    const store = {
      admin: useAdminStore(),
      modal: useModalStore(),
    };
    const { modal, openModal, closeModal, closeAllModals } = store.modal;
    const {
      loading,
      orders,
      ordersCount,
      params,
      default: defaultPagination,
      pagination,
      totalCount,
    } = storeToRefs(store.admin);

    const mode = ref<Mode>(null);
    const modeTitle = computed(() => {
      switch (mode.value) {
        case 30:
          return "Новые заказы";
        case 50:
          return "Доставляются";
        case 100:
          return "Доставлены";
        case -100:
          return "Отменены";

        default:
          return "Все";
      }
    });
    const handleChangeMode = async (m: Mode = null) => {
      mode.value = m;

      const query = { ...route.query };
      delete query.adminMode;
      router.replace({ query });

      if (m === null) {
        await store.admin.fetchAdminOrdersCount();
      }
    };

    // order data + infinity scroll
    const fetchOrders = async (mode: Mode, replacePrev: boolean = false) => {
      if (mode) {
        await store.admin.fetchAdminOrders(
          {
            page: params.value.orders.page,
            pageSize: params.value.orders.pageSize,
            state: mode,
          },
          replacePrev
        );
      } else {
        return false;
      }
    };
    const handleScroll = async (event: Event) => {
      const target = event.target as HTMLElement;
      const atBottom =
        target.scrollTop + target.clientHeight >= target.scrollHeight - 1;

      if (atBottom && !loading.value) {
        await loadMore();
      }
    };
    const loadMore = async () => {
      if (pagination.value.orders?.hasPages) {
        const { page } = defaultPagination.value.orders;

        if (params.value?.orders.page) {
          params.value.orders.page += page;
        } else {
          params.value.orders.page = page;
        }

        try {
          await fetchOrders(mode.value, false);
        } catch (e) {
          console.error("loadMore error", e);
        }
      }
    };

    // modal
    const show = computed(() => {
      return modal.adminOrders;
    });
    const handleOpen = async (orderId: string) => {
      try {
        await store.admin.fetchAdminOrder(orderId);
        handleChangeMode();
        handleClose();

        openModal("adminOrder");
      } catch (e) {
        console.error("handleOpen error", e);
      }
    };
    const handleBack = () => {
      handleClose();
      openModal("admin");
    };
    const handleClose = () => {
      closeModal("adminOrders");
    };

    watch(
      () => route.query,
      ({ adminMode }) => {
        if (adminMode) {
          handleChangeMode(Number(adminMode) as Mode);
        }
      },
      { immediate: true }
    );
    watch(
      () => show.value,
      async (status: boolean) => {
        if (status) {
          await store.admin.fetchAdminOrdersCount();

          if (ordersCount.value?.[mode.value as OrderState]?.count === 0) {
            handleChangeMode();
          }
        }
      }
    );
    watch(
      () => mode.value,
      async (m: Mode) => {
        if (m) {
          store.admin.clearParams();
          await fetchOrders(m, true);
        }
      }
    );

    return {
      mode,
      modeTitle,
      handleChangeMode,

      loading,
      orders,
      ordersCount,
      totalCount,
      handleScroll,

      // modal
      show,
      handleOpen,
      handleClose,
      handleBack,
      closeAllModals,
    };
  },
});
</script>