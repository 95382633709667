<template>
  <Transition>
    <div
      v-if="show"
      key="modal-signin"
      class="popup-container"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title">
          <div class="inner">
            <h2>Оформление заказа</h2>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <Loader v-if="loading" />
          <div class="lk-content">
            <form
              action="#"
              class="popup-form simple-form"
              @submit.prevent="handleSubmit"
            >
              <div class="input-label">
                Введите ваш номер телефона для продолжения заказа
              </div>
              <div class="input-block">
                <input
                  v-model="phone"
                  type="tel"
                  v-maska
                  data-maska="+7 (###) ### ## ##"
                  placeholder="+7 (XХХ) ХХХ ХХ ХХ"
                  :disabled="loading"
                />
              </div>

              <button
                type="submit"
                class="btn"
                :disabled="phone?.length !== 18 || loading"
              >
                Сохранить номер
              </button>
            </form>
          </div>
        </div>
        <div class="enter-note">
          Пожалуйста указывайте ваш действующий номер телефона. Он необходим для
          подтверждения заказа менеджером. При невозможности связаться с вами по
          указанному номеру телефона заказ будет отменен.
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useAuthStore } from "~/store/auth";
import { useProfileStore } from "~/store/profile";
import { useModalStore } from "~/store/modal";
import { useErrorStore } from "~/store/error";

export default defineComponent({
  setup() {
    const store = {
      auth: useAuthStore(),
      modal: useModalStore(),
      profile: useProfileStore(),
      error: useErrorStore(),
    };
    const { clearBody, updateUser, updateBody } = store.profile;

    const show = computed(() => {
      const { modal } = store.modal;
      return modal.phone;
    });
    const handleClose = () => {
      store.modal.closeModal("phone");
    };

    // form
    const phone = ref<string>("");
    const loading = computed(() => {
      return store.auth.loading;
    });

    const handleSubmit = async () => {
      const regex = {
        phone: /^\+79\d{9}$/,
        filter: /[^\d+]/g,
      };
      const filteredNumber = phone.value.replace(regex.filter, "");

      if (regex.phone.test(filteredNumber)) {
        try {
          clearBody();
          await new Promise((resolve) => setTimeout(resolve, 250));

          updateBody({ phoneNumber: filteredNumber }, "user");
          await updateUser();

          handleClose();
          store.modal.openModal("checkout");
        } catch (e: any) {
          console.error("handleSubmit error", e);
        }
      } else {
        await new Promise((resolve) => setTimeout(resolve, 500));
        // инициируем пользовательскую ошибку
        const error = {
          response: {
            data: {
              code: 400,
              message:
                "Номер должен соответствовать формату телефонного номера +7 (9ХХ) ХХХ ХХ ХХ",
              statusCode: 400,
            },
            status: 400,
            statusText: "Custom error",
          },
          message: "",
        };
        store.error.setError(error);
        phone.value = "";

        // console.log("Номер телефона не соответствует формату /^+79d{9}$/");
      }
    };

    watch(
      () => show.value,
      (status: boolean) => {
        if (!status) phone.value = "";
      }
    );

    return {
      // modal
      show,
      handleClose,

      // auth
      phone,
      loading,
      handleSubmit,
    };
  },
});
</script>

<style scoped>
input:disabled,
button:disabled {
  pointer-events: none;
  opacity: 0.7;
}
</style>