<template>
  <div v-for="(digit, index) in digits" :key="index" class="input-block">
    <input
      ref="inputFields"
      v-model="otp[index]"
      type="tel"
      maxlength="1"
      v-maska
      data-maska="#"
      placeholder="0"
      :disabled="disabled"
      @input="handleInput(index, $event.target.value)"
      @focus="handleFocus(index)"
      @keydown.delete="handleDelete(index)"
    />
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: "OtpInput",
  props: {
    digits: {
      type: Number,
      default: 4,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Array as () => string[],
      required: true,
    },
  },
  emits: ["update:modelValue"],
  setup(props) {
    const otp = ref(props.modelValue);
    const inputFields = ref<HTMLInputElement[]>([]);

    const handleInput = (index: number, value: string) => {
      otp.value.splice(index, 1, value);

      const containsDigits = /\d/.test(value);
      if (
        containsDigits &&
        index < props.digits - 1 &&
        inputFields.value[index + 1]
      ) {
        inputFields.value[index + 1].focus();
      }
    };
    const handleFocus = (index: number) => {
      const input = inputFields.value[index];
      if (input && input.value) {
        input.select();
      }
    };
    const handleDelete = (index: number) => {
      if (!otp.value[index] && index > 0) {
        inputFields.value[index - 1].focus();
      }
    };

    onMounted(() => {
      if (inputFields.value[0]) inputFields.value[0].focus();
    });

    watch(
      () => props.modelValue,
      (values) => {
        otp.value = values;
      },
      { deep: true }
    );

    return {
      otp,
      inputFields,

      handleInput,
      handleFocus,
      handleDelete,
    };
  },
});
</script>

<style scoped>
input::selection {
  background: transparent;
  color: inherit;
}
input:disabled {
  pointer-events: none;
  opacity: 0.7;
}
</style>