<template>
  <Transition>
    <div
      v-if="show"
      key="modal-profile"
      class="popup-container"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title">
          <div class="inner">
            <h2 v-html="partnerFullname" />
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <div class="lk-content">
            <ul class="lk-menu">
              <li v-if="hasPermission('get_orders')">
                <a href="#" @click.prevent="handleOpen('adminOrders')">
                  <div class="icon">
                    <img width="24" height="24" src="/images/bag.svg" alt="" />
                  </div>
                  <p>Заказы</p>
                  <div v-if="totalCount" class="count">{{ totalCount }}</div>
                  <i class="arrow" />
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="icon">
                    <img
                      width="24"
                      height="24"
                      src="/images/user-cog.svg"
                      alt=""
                    />
                  </div>
                  <p>Данные покупателей</p>
                  <i class="arrow" />
                </a>
              </li>
              <li v-if="hasPermission('get_products')">
                <a href="#" @click.prevent="handleOpen('categories')">
                  <div class="icon">
                    <img
                      width="24"
                      height="24"
                      src="/images/settings.svg"
                      alt=""
                    />
                  </div>
                  <p>Редактировать товары</p>
                  <i class="arrow" />
                </a>
              </li>
              <li>
                <a href="#">
                  <div class="icon">
                    <img width="24" height="24" src="/images/mail.svg" alt="" />
                  </div>
                  <p>Чат</p>
                  <i class="arrow" />
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="handleLogout">
                  <div class="icon">
                    <img
                      width="24"
                      height="24"
                      src="/images/log-out.svg"
                      alt=""
                    />
                  </div>
                  <p>Выйти</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useAdminStore } from "~/store/admin";
import { useAuthStore } from "~/store/auth";
import { useModalStore } from "~/store/modal";
import { useProfileStore } from "~/store/profile";

type ProfileModals = "admin" | "adminOrders" | "products" | "categories";

export default defineComponent({
  setup() {
    const store = {
      admin: useAdminStore(),
      auth: useAuthStore(),
      modal: useModalStore(),
      profile: useProfileStore(),
    };

    // auth/user
    const handleLogout = async () => {
      await store.auth.logout();
      handleClose();
    };

    // modal
    const show = computed(() => {
      const { modal } = store.modal;
      return modal.admin;
    });
    const handleOpen = (modal: ProfileModals) => {
      store.modal.closeModal("admin");
      store.modal.openModal(modal);
    };
    const handleClose = () => {
      store.modal.closeModal("admin");
    };
    watch(
      () => show.value,
      async (status: boolean) => {
        if (status) await store.admin.fetchAdminOrdersCount();
      },
      { immediate: true }
    );

    // admin
    const { user } = storeToRefs(store.profile);
    const partnerFullname = computed(() => {
      const partner = user.value?.partner;
      const template = partner
        ? `
            <span>${partner?.title || ""}</span>
            <span>${partner?.inn ? `ИНН ${partner.inn}` : ""}</span>
            <span>${partner?.ogrn ? `ОГРН ${partner.ogrn}` : ""}</span>
        `
        : "Администратор";
      return template;
    });
    const { totalCount } = storeToRefs(store.admin);
    const { hasPermission } = store.admin;

    // format
    const { formatPhoneNumber } = usePhoneNumberFormatter();

    return {
      // admin
      totalCount,
      partnerFullname,
      hasPermission,

      // auth/user
      handleLogout,

      // modal
      show,
      handleOpen,
      handleClose,

      // composables
      formatPhoneNumber,
    };
  },
});
</script>

<style lang="less">
button:disabled {
  pointer-events: none;
  opacity: 0.7;
}
.inner {
  h2 {
    span {
      display: block;
      @media screen and (max-width: 767.98px) {
        display: inline;
        white-space: nowrap;
      }
    }
  }
}
</style>