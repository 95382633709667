<template>
  <div class="close-modal" @click.prevent="handleClose">
    <img width="20" height="20" src="/images/close.svg" alt="" />
  </div>
</template>

<script lang="ts">
import { useModalStore } from "~/store/modal";

export default defineComponent({
  emits: ["close"],
  setup(_, { emit }) {
    const { closeAllModals } = useModalStore();

    const handleClose = () => {
      emit("close");
      closeAllModals();
    };

    return { handleClose };
  },
});
</script>

<style scoped>
.close-modal {
  user-select: none;
}
</style>