<template>
  <Transition>
    <div v-if="show" key="modal-example" class="popup-container" @click.self="handleClose">
      <div class="popup-content">
        <div class="popup-title">
          <a href="#" class="link-back" :class="{ disabled: loading }"
            @click.prevent="mode ? handleChangeMode() : handleBack()">
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <template v-if="mode === 'cancel'">
              <h2>Отмена заказа</h2>
            </template>
            <template v-else>
              <h2>Заказ №{{ order?.serialNumber || 0 }}</h2>
              <div class="subtitle">
                от
                {{
                  $dayjs(order.updateDT || order.createDT || null).format(
                    "DD.MM.YYYY HH:mm"
                  )
                }}
              </div>
              <div v-if="order.partner?.id" class="seller">
                <img width="16" height="16" src="/images/store.svg" alt="">
                {{ order.partner?.title || order.partner?.slug }}
              </div>
            </template>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <template v-if="mode === 'cancel'">
            <div class="lk-content" style="position: relative">
              <Loader v-if="loading" />
              <div class="lk-orders-cancel">
                <a href="#" class="lk-orders-link" style="pointer-events: none">
                  <div :class="{ hidden: !order?.id }">
                    <div class="number">
                      Заказ №{{ order?.serialNumber || 0 }}
                    </div>
                    <div class="date">
                      от
                      {{
                        $dayjs(order.createDT || null).format(
                          "DD.MM.YYYY HH:mm"
                        )
                      }}
                    </div>
                    <div class="price">
                      Сумма {{ order.totalCost || 0 }} руб
                    </div>
                    <div v-if="order.partner?.id" class="seller">
                      <img width="16" height="16" src="/images/store.svg" alt="">
                      {{ order.partner?.title || order.partner?.slug }}
                    </div>
                  </div>
                </a>
              </div>
              <form action="#" class="popup-form simple-form" @submit.prevent="handleSubmit('cancel')">
                <div class="input-label">
                  Для отмены заказа введите причину отмены и нажмите кнопку
                  “Отменить заказ”
                </div>
                <div class="input-block">
                  <CommonTextarea v-model="cancelComment" placeholder="Введите причину отмены (обязательно)"
                    :max-length="1024" :disabled="loading" />
                </div>
                <button type="submit" :disabled="cancelComment?.trim()?.length < 5 || loading" class="btn">
                  Отменить заказ
                </button>
              </form>
            </div>
          </template>
          <template v-else-if="order.products?.length">
            <div style="position: relative">
              <Loader v-if="loading" />
              <div class="lk-order-open">
                <div v-for="product in order.products" :key="product.id" class="cart-product">
                  <a :href="`?product=${product.slug}`" class="image" @click.prevent="openProduct(product.slug)">
                    <CommonImage :data="product.mainImage" :height="71" :width="71" />
                  </a>
                  <div class="inner">
                    <div class="name">
                      <a :href="`?product=${product.slug}`" :class="{ disabled: loading }"
                        @click.prevent="openProduct(product.slug)">
                        {{ product.title ?? "Неизвестный продукт" }}
                      </a>
                    </div>
                    <div class="available">
                      <img
                        width="16"
                        height="16"
                        :src="product.isWeight ? '/images/weigher.svg' : '/images/box.svg'"
                        alt=""
                      />
                      {{ !!product.priceKilo ? `${product.unit} / ${priceFormat(product.priceKilo || 0)} за кг` : product.unit || "0 г" }}
                    </div>
                    <div class="counting">
                      <div class="count-number">
                        {{ Number(product.count || 0) }}
                      </div>
                      <div class="price-block">
                        <div class="price">
                          {{ priceFormat(product.cost ?? product.price ?? 0) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form v-if="order.state == 30" action="#" class="popup-form simple-form" @submit.prevent="handleSubmit">
                <div class="input-block">
                  <CommonTextarea v-model="comment" placeholder="Добавить комментарий к заказу" :max-length="1024"
                    :disabled="loading" />
                </div>
                <button type="submit" :disabled="comment?.trim()?.length < 5 || loading" class="btn">
                  {{
                    order.comment
                      ? "Изменить комментарий"
                      : "Сохранить комментарий"
                  }}
                </button>
              </form>
            </div>
          </template>
        </div>
        <div class="lk-order-bottom">
          <template v-if="order.state == 30 && mode !== 'cancel'">
            <div class="total-price">
              Итого <span>{{ priceFormat(order.totalCost || 0) }}</span>
            </div>
            <button class="btn empty-btn" :disabled="loading" @click.prevent="handleChangeMode('cancel')">
              Отменить заказ
            </button>
          </template>
          <template v-else-if="order.state == 50">
            <div class="on-way">
              Заказ в пути
              {{
                order.deliveryDT
                  ? ` с ${$dayjs(order.deliveryDT || new Date()).format(
                    "HH:mm"
                  )}`
                  : ""
              }}
            </div>
            <div class="total-price">
              Итого <span>{{ priceFormat(order.totalCost || 0) }}</span>
            </div>
          </template>
          <template v-else-if="order.state == 100">
            <div class="on-way">Заказ доставлен</div>
            <div class="total-price">
              Итого <span>{{ priceFormat(order.totalCost || 0) }}</span>
            </div>
          </template>
          <template v-else-if="order.state == -100">
            <div class="on-way">Заказ отменен</div>
            <div class="total-price">
              Итого <span>{{ priceFormat(order.totalCost || 0) }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useStore } from "~/store/index";
import { useModalStore } from "~/store/modal";
import { useProfileStore } from "~/store/profile";

type Mode = "cancel" | null;

interface OrderForm {
  comment: string;
  cancelComment: string;
}

export default defineComponent({
  setup() {
    const { metaData, replaceUrlWithoutNavigation } = useSeoTags();

    // route/router
    const router = useRouter();
    const route = useRoute();

    // store
    const store = {
      main: useStore(),
      modal: useModalStore(),
      profile: useProfileStore(),
    };
    const { fetchProductByArg } = store.main;
    const { productLoading } = storeToRefs(store.main);
    const { modal, openModal, closeModal } = store.modal;
    const { updateOrder, cancelOrder, updateBody } = store.profile;
    const { order, loading: profileLoading } = storeToRefs(store.profile);

    const loading = computed(() => {
      return profileLoading.value || productLoading.value;
    });

    // form
    const form = reactive<OrderForm>({
      comment: order.value.comment as string,
      cancelComment: "",
    });
    const handleSubmit = async (type: "cancel" | null = null) => {
      if (type === "cancel") {
        const { cancelComment } = form;

        if (cancelComment?.trim()?.length) {
          try {
            updateBody({ cancelComment }, "comment");
            await cancelOrder(order.value.id);

            handleBack();
            form.cancelComment = "";
          } catch (e) {
            console.error("handleSubmit error", e);
          }
        }
      } else {
        const { comment } = form;

        if (comment?.trim()?.length) {
          try {
            updateBody({ comment }, "comment");
            await updateOrder(order.value.id);
          } catch (e) {
            console.error("handleSubmit error", e);
          }
        }
      }
    };

    const mode = ref<Mode>(null);
    const handleChangeMode = (m: Mode = null) => {
      mode.value = m;
    };

    // modal
    const show = computed(() => {
      return modal.order;
    });
    const handleBack = () => {
      const query = { ...route.query };
      query.mode = String(order.value?.state || 30);
      router.replace({ query });

      handleClose();
      openModal("orders");
    };
    const handleClose = () => {
      closeModal("order");
    };

    // product
    const openProduct = async (slug: string) => {
      try {
        if (slug) {
          await fetchProductByArg(slug);
          useModalStore().openModal("product");

          /*
          const query = { ...route.query };
          query.product = slug;
          router.replace({ query });
          */

          const { product } = storeToRefs(store.main);
          if (product.value?.id) {
            useHead(metaData(product.value) as object);

            const { category } = product.value;
            const url = `${category?.slug ? `${category.slug}/` : ""}${slug}`;
            replaceUrlWithoutNavigation(url);
          }
        }
      } catch (e) {
        console.error("openProduct err", e);
      }
    };

    watch(
      () => show.value,
      async (status: boolean) => {
        if (!status) mode.value = null;
      }
    );
    watch(
      () => order.value.comment,
      async (comment: string | null) => {
        if (comment) form.comment = comment;
        else form.comment = "";
      },
      { immediate: true }
    );

    const { priceFormat } = usePriceFormat();

    return {
      ...toRefs(form),
      handleSubmit,

      loading,
      order,
      mode,
      handleChangeMode,

      // modal
      show,
      handleBack,
      handleClose,

      // product
      openProduct,

      // format
      priceFormat,
    };
  },
});
</script>

<style scoped>
.cart-product {
  display: flex;
  align-items: flex-start;
}
</style>