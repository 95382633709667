<template>
  <textarea
    :disabled="disabled"
    :placeholder="placeholder"
    :value="modelValue"
    :maxlength="maxLength"
    @input="$emit('update:modelValue', $event.target.value)"
    @keydown="handlePrevent"
  />
</template>

<script lang="ts">
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 255,
    },
    modelValue: {
      type: String as PropType<string>,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const handlePrevent = (event: KeyboardEvent) => {
      // блокируем возможность юзать пробел вначале
      const target = event.target as HTMLInputElement;
      if (event.key === " " && !target.value) {
        event.preventDefault();
      }
    };
    return {
      handlePrevent,
    };
  },
});
</script>
