<!-- Модалка - нет в наличии -->
<template>
  <Transition>
    <div
      v-if="show"
      key="modal-out-stock"
      class="popup-container"
      style="z-index: 1001"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title no-text">
          <div class="close-modal" @click="handleClose">
            <img width="20" height="20" src="/images/close.svg" alt="" />
          </div>
        </div>
        <div class="popup-description">
          <Loader v-if="displayLoader" />
          <div class="popup-empty-page">
            <div class="image">
              <img width="88" height="88" src="/images/package.svg" alt="" />
            </div>
            <h2>Попробуйте добавить меньшее количество либо заменить товар</h2>
            <p v-for="product in products" :key="product.id">
              <strong>{{ product.title || "Неизвестный продукт" }}</strong>
              отсутствует на складе в достаточном количестве
            </p>
            <a href="#" class="btn" @click.prevent="handleClose">К покупкам</a>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useCartStore } from "~/store/cart";
import { useModalStore } from "~/store/modal";

export default defineComponent({
  setup() {
    const store = {
      cart: useCartStore(),
      modal: useModalStore(),
    };
    const show = computed(() => {
      const { modal } = store.modal;
      return modal.outstock;
    });
    const products = computed(() => {
      return store.cart.missingProducts || [];
    });
    const handleClose = () => {
      store.modal.closeModal("outstock");
      // при закрытии модалки актуализируем данные из корзины
      if (store.cart.missingProducts.length) store.cart.updateCartProducts();
    };

    const displayLoader = ref<boolean>(true);
    watch(
      () => show.value,
      async (status: boolean) => {
        if (status) {
          await new Promise((resolve) => setTimeout(resolve, 500));
          displayLoader.value = false;
        } else {
          displayLoader.value = true;
        }
      }
    );

    return {
      show,
      displayLoader,
      products,

      handleClose,
    };
  },
});
</script>
