<template>
  <Transition>
    <div
      v-if="show"
      class="share popup-container"
      style="z-index: 1010"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title">
          <a href="#" class="link-back" @click.prevent="handleClose">
            <img width="20" height="20" alt="" src="/images/arrow-left.svg" />
          </a>
          <div class="inner">
            <h2>Поделиться</h2>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <div class="share-content">
            <ul class="share-list">
              <li
                v-for="item in ['telegram', 'whatsapp', 'viber', 'vk']"
                :key="item"
                class="share-list__item"
              >
                <a
                  :href="`#${item}`"
                  class="share-link"
                  :aria-label="`Ссылка на ${item}`"
                  @click.prevent="handleShare(item)"
                >
                  <img
                    width="20"
                    height="20"
                    :src="`/images/share/${item}.svg`"
                    alt=""
                  />
                </a>
              </li>
            </ul>
            <button
              type="button"
              class="btn"
              :disabled="copyBtn.loading"
              @click.prevent="copyToClipboard"
            >
              <input
                v-show="copyBtn.error"
                v-model="inputText"
                ref="inputRef"
              />
              <template v-if="!copyBtn.error">
                {{ copyBtn.text || "Скопировать ссылку" }}
                <img
                  width="20"
                  height="20"
                  src="/images/share/copy.svg"
                  alt=""
                />
              </template>
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useStore } from "~/store";
import { useModalStore } from "~/store/modal";

export default defineComponent({
  setup() {
    const store = {
      main: useStore(),
      modal: useModalStore(),
    };
    const { product, productLink } = storeToRefs(store.main);

    // modal
    const { modal } = storeToRefs(store.modal);
    const show = computed(() => {
      return modal.value.share;
    });
    const handleClose = () => {
      store.modal.closeModal("share");
    };

    // sharing
    const copyBtn = ref({
      text: "",
      loading: false,
      error: false,
    });
    const inputText = ref<string>("");
    const inputRef: Ref<HTMLInputElement | null> = ref(null);

    const handleShare = (action: string) => {
      const { title = "", price = "0₽", unit = "0 шт" } = product.value;
      const text = title ? `${title} - ${price}₽/${unit}` : "";
      const url = productLink.value;

      switch (action) {
        case "telegram":
          window.open(
            `https://t.me/share/url?text=${text}&url=${url}`,
            "_blank"
          );
          break;
        case "whatsapp":
          window.open(
            `https://api.whatsapp.com/send?text=${text}%20${url}`,
            "_blank"
          );
          break;
        case "viber":
          window.open(`viber://forward?text=${text}%20${url}`, "_blank");
          break;
        case "vk":
          window.open(
            `https://vk.com/share.php?url=${url}&title=${text}`,
            "_blank"
          );
          break;
        default:
          console.error("Недопустимое действие");
      }
    };
    const copyToClipboard = async () => {
      copyBtn.value.text = "";
      copyBtn.value.loading = true;

      await new Promise((resolve) => setTimeout(resolve, 500));
      inputText.value = productLink.value;

      navigator.clipboard
        .writeText(productLink.value)
        .then(async () => {
          copyBtn.value.text = "Ссылка скопирована";
          copyBtn.value.error = false;
        })
        .catch(async () => {
          copyBtn.value.text = "";
          copyBtn.value.error = true;

          const inputElement = inputRef.value;
          await new Promise((resolve) => setTimeout(resolve, 500));
          if (inputElement !== null) {
            inputElement.select();
          }
        })
        .finally(() => {
          copyBtn.value.loading = false;
        });
    };

    watch(
      () => show.value,
      (status: boolean) => {
        if (!status) {
          copyBtn.value = { text: "", loading: false, error: false };
          inputText.value = "";
        }
      }
    );

    return {
      // modal
      show,
      handleClose,

      // sharing
      productLink,
      copyBtn,
      inputText,
      inputRef,
      handleShare,
      copyToClipboard,
    };
  },
});
</script>

<style lang="less" scoped>
.share {
  // popup
  .popup {
    &-content {
      @media screen and (min-width: 768px) {
        max-width: 305px;
      }
    }
    &-title {
      border: none;
      padding-bottom: 0;
      @media screen and (min-width: 768px) {
        align-items: flex-start;
      }
    }
    &-description {
      min-height: 0;
    }
  }

  &-content {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 335px;
      height: 100%;
      margin: 0 auto;
    }
  }
  &-list {
    display: flex;
    justify-content: space-evenly;
    margin: 0 -5px 20px;
    &__item {
      margin: 0 5px;
    }
  }
  &-link {
    font-size: 0;
    background-color: #f9f9fb;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 58px;
    width: 58px;
    padding: 16px;
    transition: all 0.3s ease-out;
    @media screen and (min-width: 380px) and (max-width: 767px) {
      height: 76px;
      width: 76px;
      padding: 26px;
    }

    &:hover {
      background: rgba(122, 122, 122, 0.09);
    }
  }

  .btn {
    justify-content: space-between;
    width: 100%;
    img {
      margin: 0;
    }
    input {
      color: #fff;
      width: inherit;
      background: none;
      border: none;
    }
  }
}
</style>