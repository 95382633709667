<template>
  <Transition>
    <div
      v-if="show"
      key="modal-signin"
      class="popup-container"
      @click.self="handleClose"
    >
      <div class="popup-content">
        <div class="popup-title">
          <div class="inner">
            <h2>Выберите ваш город</h2>
          </div>
          <CommonCloseButton />
        </div>
        <div class="popup-description">
          <div v-if="mode === 'confirmation'" class="lk-content">
            <div class="city-choice">
              <template v-if="city.name">
                <p style="font-weight: 500">
                  Это ваш город <b>{{ city.name }}</b>
                  ?
                </p>
                <div class="flex-block">
                  <a
                    href="#"
                    class="btn empty-btn"
                    @click.prevent="handleConfirm"
                  >
                    Всё верно
                  </a>
                  <a href="#" class="btn" @click.prevent="mode = 'list'">
                    Другой
                  </a>
                </div>
              </template>
              <template v-else>
                <p>Не удалось определить ваш город</p>
                <a
                  href="#"
                  class="btn"
                  style="width: 100%"
                  @click.prevent="mode = 'list'"
                >
                  Выбрать город
                </a>
              </template>
            </div>
          </div>
          <div v-else class="lk-content">
            <ul class="place-menu lk-menu">
              <li v-for="city in cities" :key="city.id">
                <a href="#" @click.prevent="handleCityChoise(city)">
                  {{ city.name || `Город ${city.kladrCode || ""}` }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import { useModalStore } from "~/store/modal";
import { useCityStore } from "~/store/cities";

import type { City } from "~/types/types";

export default defineComponent({
  setup() {
    const store = {
      city: useCityStore(),
      modal: useModalStore(),
    };
    const { city, cities, confirmed } = storeToRefs(store.city);

    const mode = ref<string>("");
    const handleConfirm = () => {
      store.city.setConfirmedCityToLocalStorage(true);
      handleClose();
    };
    const handleCityChoise = (c: City) => {
      store.city.saveCityToLocalStorage(c);
      handleConfirm();
    };

    // modal
    const show = computed(() => {
      const { modal } = store.modal;
      return modal.city;
    });
    const handleClose = () => {
      store.modal.closeAllModals();
    };
    watch(
      () => show.value,
      async (status: boolean) => {
        if (!status) {
          mode.value = "";

          if (cities.value?.length) {
            if (!city.value?.id) {
              const c = cities.value[0];

              city.value = c;
              store.city.saveCityToLocalStorage(c);
            }
          }
          // исключение, если не был прогружен список городов (в теории невозможно)
          else {
            await store.city.fetchCityByCoords(44.8908, 37.3239);
          }
          store.city.setConfirmedCityToLocalStorage(true);
        }
      }
    );
    watch(
      () => confirmed.value,
      (status: boolean) => {
        if (status) {
          mode.value = "";
        } else {
          mode.value = "confirmation";
        }
      },
      { immediate: true }
    );

    return {
      city,
      cities,
      confirmed,
      mode,
      handleConfirm,
      handleCityChoise,

      show,
      handleClose,
    };
  },
});
</script>