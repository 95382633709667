<template>
  <template v-if="cat.children?.length">
    <a
      :href="`/${cat.slug}`"
      class="catalog-menu-link"
      @click.prevent="toggleSubcategories(cat)"
    >
      <img v-if="cat.icon" :src="cat.icon" width="20" height="20" alt="" />
      <div v-else class="no-icon"></div>
      <span>{{ cat.title }}</span>
      <i />
    </a>
    <Transition name="collapse">
      <ul
        v-if="cat.collapsed"
        class="catalog-podmenu"
        :style="{ display: cat.collapsed ? 'block' : 'none' }"
      >
        <li
          v-for="subcat in cat.children"
          :key="subcat.id"
          :class="{ current: subcat.slug === $route.params.category }"
        >
          <NuxtLink
            :to="toParams(`/${subcat.slug}`)"
            @click.prevent="handleClose"
          >
            {{ subcat.title }}
          </NuxtLink>
        </li>
      </ul>
    </Transition>
  </template>
  <template v-else>
    <NuxtLink
      :to="toParams(`/${cat.slug}`)"
      class="catalog-menu-link"
      @click="clearSearch"
    >
      <img v-if="cat.icon" :src="cat.icon" width="20" height="20" alt="" />
      <div v-else class="no-icon"></div>
      <span>{{ cat.title }}</span>
    </NuxtLink>
  </template>
</template>

<script lang="ts">
import { useStore } from "~/store";
import { useModalStore } from "~/store/modal";
import type { ModifiedCategory } from "~/types/types";

export default defineComponent({
  props: {
    cat: {
      type: Object as PropType<ModifiedCategory>,
      required: true,
    },
  },
  emits: ["toggleSubcategories"],
  setup(props, { emit }) {
    const store = {
      main: useStore(),
      modal: useModalStore(),
    };
    const { routeParameters } = useQueryNavigation();
    const toggleSubcategories = () => {
      emit("toggleSubcategories", props.cat);
    };

    const handleClose = async () => {
      await new Promise((resolve) => setTimeout(resolve, 250));
      store.modal.closeMobileModal("catalog");
      clearSearch();
    };

    const { params } = storeToRefs(store.main);
    const clearSearch = () => {
      if (params.value.search) {
        store.main.updateParams({
          search: null,
        });
      }
    };

    return {
      toggleSubcategories,
      toParams: routeParameters,

      handleClose,
      clearSearch,
    };
  },
});
</script>

<style scoped>
.no-icon {
  flex-shrink: 0;
  height: 20px;
  width: 20px;
  margin-right: 10px;
}
.catalog-menu-link {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
</style>
